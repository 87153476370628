/** @format */

import axios from "axios";
import { deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "firemade";
import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import TimeAgo from "react-timeago";

//ASSETS
import ChatIcon from "../../safersolar/src/assets/images/chat-icon.svg";
import SendArrow from "../../safersolar/src/assets/images/right-send-arrow.png";
import orangeCircle from "../../safersolar/src/assets/images/orange-circle.svg";
import whiteCross from "../../safersolar/src/assets/images/white-cross.svg";

import "./index.css";

/**
 * Formats a value and unit into a string.
 *
 * @param value - The value to be formatted.
 * @param unit - The unit to be shortened and included in the formatted string.
 * @returns The formatted string.
 */
const formatter = (value, unit) => {
  // Check if the time is less than 60 seconds
  if (unit === "second" && value < 60) {
    return "Moments ago";
  }
  // Otherwise, use the original formatting
  return `${value}${unit.charAt(0)}`;
};

/**
 * Removes sources from the given message (fallback when openai ignores this instruction)
 *
 * @param {string} message - The message to remove sources from.
 * @returns {string} The message with sources removed.
 */
const removeSources = (message) => {
  return message.replace(/【.*?】/g, "");
};

/**
 * Renders a chat message component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.sessionId - The session ID.
 * @returns {JSX.Element} The rendered component.
 */
function Chat({ sessionId, visible = false, logo }) {
  // State for the messages (these can come out if not using - probably not using)
  const [error, setError] = useState(false);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  // Lock some components when working (these can come out if not using - probably not using)
  const [working, setWorking] = useState(false);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const toggleChat = () => setIsChatOpen(!isChatOpen);

  // Create a ref for the chat container
  const chatContainerRef = useRef();

  // Manages the collection/messages
  useEffect(() => {
    if (sessionId && visible) {
      // Remove document listener when done
      const unsubscribe = onSnapshot(doc(db, "chat", sessionId), (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          let messages = data.messages || [];

          // Filter out 'working' messages that aren't at the last index
          messages = messages.filter((message, index, array) => {
            // Keep the message if it is not of type 'working', or if it is the last one in the array
            return message.type !== "working" || index === array.length - 1;
          });

          setMessages(messages);
        } else {
          // Document does not exist, which means no results have been set
          setMessages([
            {
              type: "text",
              message: "I'm a friendly solar assistant. Ask me anything!",
              from: "assistant",
              createdAt: new Date().toISOString(),
            },
          ]);
        }
      });

      return () => unsubscribe();
    }
  }, [sessionId, visible]);

  // Add a new useEffect hook that depends on the messages array
  useEffect(() => {
    if (!visible) return;
    if (!chatContainerRef.current) return;
    // Scroll the chat container to the bottom when messages change
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [visible, messages]);

  // Submit it to the api endpoint for chat
  const handleSubmit = async (e) => {
    e.preventDefault();

    setWorking(true);
    setError(false);

    // Get the temporary message (to reset the state responsively)
    const tempMessage = message;

    // Reset the text field immesiage
    setMessage("");

    try {
      // A a temp message to the state (to show the user immediate action - it will be removed by the backend)
      setMessages((messages) => [
        ...messages,
        {
          type: "text",
          message: tempMessage,
          from: "user",
          createdAt: new Date().toISOString(),
        },
      ]);

      // Send it to the chat endpoint
      await axios.post(
        "/api/chat",
        {
          sessionId,
          message: tempMessage,
        },
        { timeout: 60000 }
      );

      setError(false);
    } catch (_) {
      setError(true);
    } finally {
      setWorking(false);
    }
  };

  // This will handle errors (adding a temporary client-side error)
  useEffect(() => {
    // If there is an error, add a temporary message to the state
    if (error) {
      setMessages((messages) => [
        ...messages.filter((message) => message.type !== "working"),
        {
          type: "text",
          message: "Looks like there was an error. Please try again.",
          from: "assistant",
          createdAt: new Date().toISOString(),
        },
      ]);
    }
  }, [error]);

  const getChatIcon = () => {
    const closeChatIcon = (
      <div
        onClick={toggleChat}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          width: "80px",
          height: "80px",
        }}
      >
        <img
          src={orangeCircle}
          alt="Orange Circle"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
        <img
          src={whiteCross}
          alt="White Cross"
          style={{
            position: "absolute",
            width: "50px",
            height: "50px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
          }}
        />
      </div>
    );

    if (!isChatOpen) {
      return (
        <button
          onClick={toggleChat}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
          }}
          className="chat-button"
          aria-label="Open chat"
        >
          <img src={ChatIcon} alt="Chat Icon" />
        </button>
      );
    } else {
      return closeChatIcon;
    }
  };

  const ChatButtonAndBox = () => {
    return (
      <>
        {getChatIcon()}
        {isChatOpen && (
          <div className="chat-container">
            {messages.length > 0 && (
              <div ref={chatContainerRef} className="message-list">
                {messages.map((message, index) =>
                  message.type === "text" ? (
                    <div
                      key={index}
                      className={`message ${
                        message.from == "user"
                          ? "message-user"
                          : "message-other"
                      }`}
                    >
                      <div
                        className={`message-arrow ${
                          message.from === "user" ? "arrow-user" : "arrow-other"
                        }`}
                      ></div>
                      <div className="message-header">
                        <strong>
                          {message.from === "user" ? "You" : "Safer Solar"}
                        </strong>
                        <small>
                          <TimeAgo
                            date={message.createdAt}
                            formatter={formatter}
                          />
                        </small>
                      </div>
                      <Markdown>{removeSources(message.message)}</Markdown>
                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        margin: "20px 10px",
                      }}
                    >
                      <img
                        loading="lazy"
                        src={logo}
                        alt="Logo"
                        className={
                          "aspect-square object-contain object-center w-7 fill-yellow-500 overflow-hidden shrink-0 max-w-full my-auto animate-spin"
                        }
                      />
                    </div>
                  )
                )}
              </div>
            )}
            <form onSubmit={handleSubmit} className="message-form">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Type your message here..."
                  className="message-input"
                  value={message}
                  disabled={working}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      (e.shiftKey || e.ctrlKey || message.trim() === "")
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <button
                  disabled={working}
                  type="submit"
                  className="send-button"
                >
                  <img src={SendArrow} alt="Send" className="send-icon" />
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    );
  };

  // Don't show the chat until the container component is ready
  if (!visible) return null;

  return <>{ChatButtonAndBox()}</>;
}

/**
 * Deletes a chat document based on the provided session ID.
 *
 * @async
 * @function resetChat
 * @param {string} sessionId - The session ID of the chat document to delete.
 * @returns {Promise<void>} A promise that resolves when the document is deleted.
 */
export async function resetChat(sessionId) {
  try {
    // Reference to the chat document
    const chatRef = doc(db, "chat", sessionId);

    // Delete the document
    await deleteDoc(chatRef);
  } catch (_) {}
}

export default Chat;
