/** @format */
import axios from "axios";

// Step 1: Geocode the address to get lat/lng
export async function getLatLng({ address, apiKey }) {
	try {
		const geocodeResp = await axios.get(
			`https://maps.googleapis.com/maps/api/geocode/json`,
			{
				params: { address, key: apiKey },
			}
		);
		const { lat, lng } = geocodeResp.data.results[0].geometry.location;

		return {
			lat: lat,
			lng: lng,
		};
	} catch (error) {
		console.error("Error in processing:", error);
	}
}
