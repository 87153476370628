import { auth, provider, db } from "/packages/firemade/src/index.js";
import { signInWithPopup } from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // const credential = provider.credentialFromResult(result);
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential.accessToken;
      const user = result.user;


      // Define the userRef variable
      const userRef = doc(db, 'users', user.uid);


      await setDoc(userRef, {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        // You can also save the token if you need to use it in your frontend app.
        // Remember that tokens have an expiry date and should be handled securely.
        accessToken: token
      }, { merge: true }); // Use merge option to avoid overwriting entire document if it exists

      console.log("User logged in and data saved to Firestore:", user);
    })
    .catch((error) => {
      // Handle Errors here.
      console.error("Error during sign-in:", error);
    });
};