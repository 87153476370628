/** @format */

import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./page.css";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

//doc
import ContractorInfo from "../components/ContractorInfo";
import GearInfo from "../components/GearInfo";
import OverviewInfo from "../components/OverviewInfo";
import ProposalDetails from "../components/ProposalDetails";
import WarrantyInfo from "../components/WarrantyInfo";
import Footer from "../components/Footer";
import { mockdata } from "../Page/mockdata";

// Import Firestore functions and database instance
import { doc, getDoc, getDocs, collection, query, where, onSnapshot, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '/packages/firemade/src/index.js';
import { getAuth } from "firebase/auth";


//Modals
import AddressModal from "../components/modals/AddressModal";

// SOLAR ROOF STUFF
// import SolarRoof from "../components/SolarRoof";

//ASSETS
import Download from "../assets/images/download.svg";
import Logo from "../assets/images/logo2.svg";
import ToolTip from "../assets/images/tooltip.svg";

//UTILS
import { downloadPDF } from "../utils/utils";

//CHAT
import Chat from "chat";
import Todo from "../components/Todo";

// The loading image
// import Loading from "../../assets/images/Loading.gif";

// Use the global error boundary

/**
 * Formats the given price as USD currency.
 *
 * @param {string|number} price - The price to be formatted.
 * @returns {string} The formatted price as USD currency.
 */

/**
 * Formats a number by converting it to a locale-specific string representation.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number as a string.
 */

/**
 * Returns the color class and grade based on the warranty period.
 *
 * @param {string} warrantyPeriod - The warranty period.
 * @returns {Object} - An object containing the color class and grade.
 */

/**
 * Merge component.
 * Handles submission and updates of the form.
 *
 * @returns {JSX.Element} The Merge component.
 */
const Results = ({
	error,
	working,
	sessionId,
	submission,
	resetSession,
	handleUpload,
	emailOnError,
	addressOnError,
	showProgressBar,
	removeSubmission,
}) => {
	// Setup the states
	const [data, setData] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoadingNewData, setIsLoadingNewData] = useState(false);
	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState({
		title: "",
		message: "",
		isNotProposal: false,
	});
	const [estimatedAmounts, setEstimatedAmounts] = useState({
		estimatedPrice: 0,
		estimatedYield: 0,
	});

	const { documentId } = useParams();

	const [isCopied, setIsCopied] = useState(false);

	const generateShareableLink = (documentId) => {
		const baseUrl = window.location.origin; // Get the base URL of your app
		return `${baseUrl}/results/${documentId}`;
	};

	const copyToClipboard = () => {
		const shareableLink = generateShareableLink(documentId || sessionId);
		navigator.clipboard.writeText(shareableLink).then(() => {
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		});
	};

	const fileInputRef = useRef(null);

	/**
	 * Triggers the hidden file input.
	 */
	const triggerFileInput = () => {
		// Trigger the hidden file input
		fileInputRef.current.click();
	};

	/**
	 * Handles the change event of the file input element.
	 * @param {Event} event - The change event object.
	 */
	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			handleUpload(selectedFile); // Directly call handleSubmit here
		}
	};

	/**
	 * Handles the file drop event.
	 *
	 * @param {Event} event - The drop event.
	 */
	const handleFileDrop = (event) => {
		event.preventDefault();
		const droppedFile = event.dataTransfer.files[0];
		if (droppedFile) {
			handleUpload(droppedFile); // Directly call handleSubmit here
		}
	};

	/**
	 * Handles the drag over event.
	 * @param {Event} e - The drag over event.
	 */
	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const determineModalContent = (submission, error, data) => {
		if (error && submission?.status !== undefined) {
			return {
				isOpen: true,
				content: {
					title: "Get Results",
					message:
						"We’ll email your results as soon as they’re ready!",
				},
			};
		} else if (data.FinanceType === "Lease") {
			return {
				isOpen: true,
				content: {
					title: "Lease Proposal",
					message:
						"We currently don’t support automated reviews for lease proposals. Please enter your email and we’ll have someone reach out with more information about your lease.",
				},
			};
		} else if (
			data.Scope === "Solar and Batteries" ||
			data.Scope === "Solar and Other"
		) {
			return {
				isOpen: true,
				content: {
					title: "Proposal Type",
					message: `We’re currently only reviewing solar proposals. Please come back later for your proposal ${data?.Scope} to be reviewed.`,
					isSolar: false,
				},
			};
		} else if (data.FinanceType === "Not a proposal") {
			return {
				isOpen: true,
				content: {
					title: "Upload Needed",
					message: "Please upload a solar proposal.",
					isNotProposal: true,
				},
			};
		}
		return { isOpen: false };
	};
	useEffect(() => {
		const modalState = determineModalContent(submission, error, data);
		if (modalState.isOpen) {
			setIsModalOpen(true);
			setModalContent(modalState.content);
		}

		if (
			submission?.status === "complete" &&
			data?.Address === "Not Found" &&
			!isLoadingNewData
		) {
			// The address is not found, and we are not currently loading new data.
			setIsAddressModalOpen(true);
		} else if (error || data?.Address !== "Not Found") {
			// If there is an error or the address is found, we do not want to show the modal.
			setIsAddressModalOpen(false);
		}

		if (!error && data?.Address && data?.Address !== "Not Found") {
			// The new data has been loaded (address is neither "Not Found" nor empty)
			setIsLoadingNewData(false);
		}

		if (submission?.result) {
			const {
				GrossPrice = 0,
				MaxPrice = 0,
				QuotedYield = 0,
				EstimatedYield = 0,
			} = submission.result;
			let formattedMaxPrice = parseFloat(MaxPrice);
			let formattedGrossPrice = parseFloat(GrossPrice);
			let estimatedPrice = (
				formattedGrossPrice - formattedMaxPrice
			).toFixed(2);

			let formattedQuotedYield = parseInt(QuotedYield, 10);
			let formattedEstimatedYield = parseInt(EstimatedYield, 10);

			let estimatedYieldDifference =
				formattedQuotedYield - formattedEstimatedYield;

			setEstimatedAmounts({
				estimatedPrice: estimatedPrice,
				estimatedYield: estimatedYieldDifference,
			});

			setData(submission.result || documentData.result);
		}
	}, [submission, error, data]);

	const logoHeader = () => {
		return (
			<div className="items-center self-center flex gap-1 mt-12 px-5 max-md:mt-10">
				{/* Wrap the logo with div and add onClick event */}
				<div onClick={removeSubmission} className="cursor-pointer">
					<img
						loading="lazy"
						src={Logo}
						alt="Logo"
						className={classnames(
							"aspect-square object-contain object-center w-7 fill-yellow-500 overflow-hidden shrink-0 max-w-full my-auto",
							working && "animate-spin"
						)}
					/>
				</div>
				{/* Wrap the logo with div and add onClick event */}
				<div onClick={removeSubmission} className="cursor-pointer">
					<div className="flex items-center">
						<div className="text-zinc-800 text-center text-xl font-medium leading-8 self-stretch grow whitespace-nowrap">
							SaferSolar
						</div>
						<div
							className={classnames(
								"text-beta text-center font-medium leading-6 self-stretch grow whitespace-nowrap text-gray-600 mt-1",
								"beta-text"
							)}
						>
							Beta
						</div>
					</div>
				</div>
			</div>
		);
	};

	const tagLine = () => {
		if (!submission?.url && !working) {
			return (
				<>
					<div className="self-center text-neutral-700 text-3xl font-semibold leading-10 w-[432px] max-w-full mt-10 text-left">
						Don't get sun <em>burned</em>.
					</div>
					<div className="self-center text-black-700 text-base leading-7 w-[432px] max-w-full mt-2 text-left">
						Upload your solar proposal and receive a review in 60
						seconds.
					</div>
				</>
			);
		}
	};

	const HamburgerMenu = () => {
		const [isOpen, setIsOpen] = useState(false);
		const [records, setRecords] = useState([]);

		const auth = getAuth();
		const user = auth.currentUser;
		const userId = user ? user.uid : null;

		// console.log(userId);

		useEffect(() => {
			const fetchRecords = async () => {
				// console.log("Results.jsx line 322 useEffect");
				try {
					const recordsRef = collection(db, 'submissions');
					const q = query(recordsRef, where('userId', '==', userId));
					const querySnapshot = await getDocs(q);
					const fetchedRecords = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));


					// console.log(fetchedRecords);
					setRecords(fetchedRecords);
				} catch (error) {
					console.error('Error fetching records:', error);
				}
			};

			if (userId) {
				fetchRecords();
			}
		}, [userId]);

		const toggleMenu = () => {
			setIsOpen(!isOpen);
		};

		return (
			<div>
				<button
					className="fixed top-4 left-4 z-50 focus:outline-none"
					onClick={toggleMenu}
				>
					<div className="relative w-6 h-6 bg-[#e5e7eb] rounded flex items-center justify-center">
						<div
							className={`absolute w-4 h-0.5 bg-black transition duration-300 ease-in-out ${isOpen ? 'transform rotate-45' : ''
								}`}
						></div>
						<div
							className={`absolute w-4 h-0.5 bg-black transition duration-300 ease-in-out ${isOpen ? 'opacity-0' : ''
								}`}
						></div>
						<div
							className={`absolute w-4 h-0.5 bg-black transition duration-300 ease-in-out ${isOpen ? 'transform -rotate-45' : ''
								}`}
						></div>
					</div>
				</button>
				<div
					className={`fixed top-0 left-0 h-screen w-64 bg-gray-100 z-40 transition-all duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'
						}`}
				>
					<ul className="mt-20 ml-4 mr-4">
						{records.map((record, i) => <div key={i} className="py-4">{<a href={`/results/${record.id}`}>{record.file}</a>}</div>)}
					</ul>
				</div>
			</div>
		);
	};

	const getErrorMsg = () => {
		if (error) {
			return (
				<div className="items-stretch self-center border border-neutral-200 bg-zinc-50 flex w-[432px] max-w-full flex-col mt-10 p-8 rounded-lg border-solid max-md:px-5">
					Sorry, there was an error.
					<button
						className="bg-blue-500 hover:bg-blue-700 text-black py-2 px-4 rounded"
						onClick={removeSubmission}
					>
						Try again.
					</button>
				</div>
			);
		}
	};
	const EmailModal = ({ isOpen, onClose, onSubmit, modalContent }) => {
		const [email, setEmail] = useState("");
		const [finished, setFinished] = useState(false);

		useEffect(() => {
			let timer;
			if (finished) {
				timer = setTimeout(() => {
					onClose();
				}, 3000);
			}
			return () => clearTimeout(timer);
		}, [finished, onClose]);

		if (!isOpen) return null;

		const finish = () => {
			setFinished(true);
			onSubmit(email);
		};

		return ReactDOM.createPortal(
			<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
				<div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-lg font-semibold">
							{modalContent.title}
						</h2>
						<button
							onClick={onClose}
							className="text-black hover:text-gray-700"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M6 18L18 6M6 6l12 12"></path>
							</svg>
						</button>
					</div>
					{!finished ? (
						<>
							<p className="mb-4">{modalContent.message}</p>
							{modalContent.isNotProposal ? null : (
								<input
									type="email"
									placeholder="Enter your email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="input border border-gray-300 p-2 mb-4 w-full rounded"
								/>
							)}
							<button
								onClick={
									modalContent.isNotProposal
										? handleOnClose
										: finish
								}
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
							>
								{modalContent.isNotProposal
									? "Start Over"
									: "Submit"}
							</button>
						</>
					) : (
						<>Thanks! We'll get back to you ASAP.</>
					)}
				</div>
			</div>,
			document.body
		);
	};

	const handleEmailSubmit = (email) => {
		emailOnError(email);
		setTimeout(() => {
			setIsModalOpen(false);
			resetSession();
		}, 2000);
	};
	const handleAddressSubmit = async (address) => {
		setIsLoadingNewData(true); // Start the submission/loading process
		setData((prevData) => ({ ...prevData, Address: "Loading..." })); // Temporary state

		await addressOnError(address); // send the address to the backend
	};

	const handleOnClose = () => {
		setIsModalOpen(false);
		resetSession();
	};

	const getUploader = () => {
		if (!submission?.url && !working) {
			return (
				<div
					className="items-stretch self-center border border-neutral-200 bg-zinc-50 flex w-[432px] max-w-full flex-col mt-10 p-8 rounded-lg border-solid max-md:px-5 mb-80"
					onClick={triggerFileInput}
					onDrop={handleFileDrop}
					onDragOver={handleDragOver}
				>
					<input
						type="file"
						style={{ display: "none" }}
						onChange={handleFileChange}
						accept="application/pdf, image/png, image/jpeg"
						ref={fileInputRef}
					/>
					<div className="text-neutral-400 text-center text-xs leading-5 whitespace-nowrap">
						Analyze your proposal
					</div>
					<img
						loading="lazy"
						src={Download}
						alt="Download"
						className="aspect-square object-contain object-center w-[47px] overflow-hidden self-center max-w-full mt-7 cursor-pointer"
					/>
					<div className="text-neutral-400 text-center text-xs leading-5 self-center whitespace-nowrap mt-7 cursor-pointer">
						<span className="text-neutral-600">Choose file</span> or
						drag here
					</div>
				</div>
			);
		}
	};

	const getProgressMessage = () => {
		if (showProgressBar) {
			return (
				<div className="self-center py-10 text-black-700 text-base leading-7 w-[432px] max-w-full mt-2 text-left">
					<div className="dark:bg-gray-700 h-2.5 rounded-full overflow-hidden relative">
						<div className="bg-gray-200 h-4 absolute animate-progress"></div>
					</div>
					<div className="pt-2 text-center text-xs">
						{submission?.messages?.at(-1)?.message}
					</div>
				</div>
			);
		}
	};
	const getReportContent = () => {
		if (submission?.result) {
			// console.log(submission.result);
			return (
				<div className="bg-white flex flex-col justify-center items-center px-16 py-2 max-md:px-5">
					<div className="flex w-[762x] max-w-full flex-col mt-1.5 ">
						{/* Document */}
						<div className="self-stretch flex flex-col mt-24 px-7 items-start max-md:max-w-full max-md:mt-10 max-md:px-5">
							{/* Solar Proposal */}
							<div className="text-neutral-700 text-2xl font-semibold leading-8 self-stretch max-md:max-w-full">
								Proposal Analysis
							</div>
							<div className="text-sm text-black mt-2">
								{submission?.file ? submission?.file : null}
							</div>
							<div className="flex items-center mt-10 mb-10">
								<img
									loading="lazy"
									src={ToolTip}
									alt="Tooltip"
									className="w-6 h-6 mr-2"
								/>
								<div className="text-neutral-500 text-sm">
									Hover over any of the headers/results below
									to show a tooltip with more information.
								</div>
							</div>
							{/* Proposal Details */}
							<ProposalDetails data={data} />

							{/* Analysis */}
							<div className="self-stretch flex w-full justify-between gap-5 mt-32 pr-1.5 items-start max-md:max-w-full max-md:flex-wrap max-md:mt-10">
								<div className="text-neutral-700 text-2xl font-semibold leading-8">
									Analysis
								</div>
							</div>
						</div>
						<div className="bg-neutral-200 self-stretch shrink-0 h-px mt-5 max-md:max-w-full" />


						<OverviewInfo
							data={data}
							estimatedAmounts={estimatedAmounts} />


						<div className="bg-neutral-200 self-stretch shrink-0 h-px mt-14 max-md:max-w-full max-md:mt-10" />
						{/* Gear */}
						<GearInfo data={data} />

						{/* Divider */}
						<div className="bg-neutral-200 self-stretch shrink-0 h-px mt-14 max-md:max-w-full max-md:mt-10" />
						{/* Warranties */}

						<WarrantyInfo data={data} />

						{/* Divider */}
						<div className="bg-neutral-200 self-stretch shrink-0 h-px mt-14 max-md:max-w-full max-md:mt-10" />
						{/* Contractors */}

						<ContractorInfo data={data} />

						<Todo data={data} />

						{/* Bottom Buttons */}

						<div className="bg-neutral-200 self-stretch shrink-0 h-px mt-5 max-md:max-w-full" />
						<Chat
							logo={Logo}
							visible={submission.status == "complete"}
							sessionId={sessionId}
						/>

						<div className="flex justify-center mt-5">
							{(submission.status == "complete" ||
								submission.status == "failed" ||
								submission.status == "started") && (
									<>
										<div className="self-center mx-2">
											<button
												className="bg-blue-100 hover:bg-blue-500 text-black py-2 px-4 rounded"
												onClick={removeSubmission}
											>
												Start Over
											</button>
										</div>
										<div className="self-center mx-2">
											<button
												className="bg-blue-100 hover:bg-blue-500 text-black py-2 px-4 rounded"
												onClick={downloadPDF}
											>
												Download PDF
											</button>
										</div>
										<div>
											<button className="bg-blue-100 hover:bg-blue-500 text-black py-2 px-4 rounded" onClick={copyToClipboard}>
												{isCopied ? 'Copied!' : 'Copy Shareable Link'}
											</button>
										</div>
									</>
								)}
						</div>
					</div>
				</div>
			);
		}
	};
	return (
		<div className="bg-white flex flex-col p-6 pb-12">
			{<HamburgerMenu />}
			{/* Logo/Header */}
			{logoHeader()}
			{/* Tagline */}
			{tagLine()}
			<EmailModal
				isOpen={isModalOpen}
				onClose={handleOnClose}
				onSubmit={handleEmailSubmit}
				modalContent={modalContent}
			/>
			<AddressModal
				isOpen={isAddressModalOpen && !isLoadingNewData}
				onClose={() => setIsAddressModalOpen(false)}
				onSubmit={handleAddressSubmit}
				isSubmitting={isLoadingNewData}
			/>
			{/* Some progress messages */}
			{getProgressMessage()}
			{/* Uploader */}
			{getUploader()}
			{getErrorMsg()}
			{!isModalOpen && getReportContent()}
			{(submission?.result || (!submission?.url && !working)) &&
				!isModalOpen && <Footer />}
		</div>
	);
};

export default Results;