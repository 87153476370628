/** @format */
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";

// Helpers
import { getBuilding } from "./helpers/building";
import { getLatLng } from "./helpers/latLng";
import { getLayer } from "./helpers/layer";
import { getLayers } from "./helpers/layers";

const SolarRoof = ({
	apiKey = "AIzaSyDeKB53P-ViZv4XTrnWfhuqllYz25Elaqc",
	address,
}) => {
	const [center, setCenter] = useState(null);
	const [overlays, setOverlays] = useState([]);

	const mapRef = useRef(null);

	useEffect(() => {
		const load = async () => {
			clearOverlays();

			// First get the lat long from the address
			const latLng = await getLatLng({ address, apiKey });

			// Set the center
			if (latLng) setCenter(latLng);

			// Get the building info
			const building = await getBuilding({
				...latLng,
				apiKey,
			});

			// Set the center
			if (building)
				setCenter({
					lat: building.center.latitude,
					lng: building.center.longitude,
				});

			// Get the building info
			const urls = await getLayers({
				...building,
				apiKey,
			});

			// Get the overlays
			const layerData = await getLayer({
				layerId: "mask",
				urls,
				apiKey,
			});

			layerData.render(false).forEach((canvas) => {
				const dataUrl = canvas.toDataURL();
				const bounds = new window.google.maps.LatLngBounds(
					new window.google.maps.LatLng(
						layerData.bounds.south,
						layerData.bounds.west
					),
					new window.google.maps.LatLng(
						layerData.bounds.north,
						layerData.bounds.east
					)
				);

				const overlay = new window.google.maps.GroundOverlay(dataUrl, bounds);
				overlay.setMap(mapRef.current);
				setOverlays((prevOverlays) => [...prevOverlays, overlay]);
			});
		};

		load();
	}, [address, apiKey]);

	// Clear the overlays
	const clearOverlays = () => {
		overlays.forEach((overlay) => overlay.setMap(null));
		setOverlays([]);
	};

	return (
		<>
			<LoadScript googleMapsApiKey={apiKey}>
				{center && (
					<GoogleMap
						center={center}
						zoom={20}
						mapContainerStyle={{ height: "400px", width: "400px" }}
						options={{
							disableDefaultUI: true,
							zoomControl: false,
							streetViewControl: false,
							mapTypeControl: false,
							draggable: false,
							// mapTypeId: "satellite",
							// tilt: 0,
						}}
						ref={mapRef}
						onLoad={(map) => (mapRef.current = map)}
					/>
				)}
			</LoadScript>
		</>
	);
};

export default SolarRoof;
