import React, { useState } from "react";

//UI
import Skeleton from "../ui/Skeleton";
import { Boundary } from "frontend";

const Tooltip = ({ children }) => {
  return (
    <div className="absolute top-[2rem] left-0 ml-15 p-2 flex items-center bg-gray-800 text-white rounded-md w-[400px] z-10 text-xs break-words overflow-auto">
      {children}
    </div>
  );
};

const ProposalDetails = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const proposalData = [
    {
      name: "Proposal Type",
      value: data["FinanceType"],
      tooltip: <div>
        <p>Contractors usually offer cash, finance, or lease deals.</p>
        <br></br>
        <p>• <strong>Cash deals</strong> offer the lowest price and total cost of ownership.</p>
        <br></br>
        <p>• <strong>Finance deals</strong> offer the lowest upfront cost (often $0 down) <br />but require monthly payments and a higher total cost of ownership.</p>
      </div>
    },
    {
      name: "Scope of work",
      value: data["Scope"],
      tooltip: "",
    },
  ];

  return (
    <div className="flex items-stretch justify-between gap-5 mt-6">
      {proposalData.map((item, index) => (
        <div
          key={item.name}
          className={`flex flex-col items-stretch ${item.tooltip ? "cursor-pointer" : ""
            }`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className="text-neutral-700 text-base font-semibold leading-6 whitespace-nowrap">
            {item.name}
          </div>
          <Boundary>
            <div className="text-neutral-700 text-base leading-6 whitespace-nowrap mt-2 relative">
              {item.value || <Skeleton />}
              {hoveredIndex === index && item.tooltip && item.value && (
                <Tooltip>{item.tooltip}</Tooltip>
              )}
            </div>
          </Boundary>
        </div>
      ))}
    </div>
  );
};

export default ProposalDetails;
