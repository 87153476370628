import React from 'react';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div className="container mx-auto py-8 max-w-5xl">
      <div className="container mx-auto px-4 py-8 max-w-3xl mb-20">
        <h1 className="text-3xl font-bold text-center mb-10 text-neutral-700">Making Solar Safer for Homeowners</h1>
        <p className="mb-8">
          Hi, I'm Dane Myers, founder of SaferSolar. Whether you're hesitant, curious, or overwhelmed by solar energy, that's why SaferSolar exists.
        </p>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="flex flex-col justify-center mb-8 h-full">
            <p>
              Working in solar, I witnessed the rise in solar adoption and its challenges. Family and friends often sent me solar quotes for review, where I found proposals inflated by thousands of dollars. <br /> <br />Companies frequently over-promised system production to justify higher prices, while reputable installers lost bids to less reliable ones offering unrealistic promises. <br /> <br />
              That’s where the idea for SaferSolar was born. To create an AI tool that clarifies proposals in a sales-aggressive and sometimes misleading solar industry.
            </p>
          </div>

          <div>
            <iframe
              title="Datawrapper Chart"
              src="https://datawrapper.dwcdn.net/mPgqd/2/"
              style={{ width: '100%', height: '400px', border: 'none' }}
              allowTransparency
            ></iframe>
          </div>
        </div>
        <div>
          <p className="pt-10">
            It aims to support your clean energy journey, help reputable contractors, and uphold the industry's integrity. I hope we can help you safely join the solar movement!
          </p>


          <p>
            <br />
            Warm regards, <br /> <br />
            <b>Dane Myers</b> <br />
            Founder & CEO, SaferSolar
          </p>
        </div >

        <div className="self-center flex w-full max-w-[1272px] justify-between gap-5 px-5 items-start max-md:max-w-full max-md:flex-wrap max-md:mt-10">

        </div>
      </div>
      <Footer />
    </div >
  );
};

export default AboutUs;
