/**
 * Initializes the Sentry error tracking and monitoring system.
 * @param {string} dsn - The Data Source Name (DSN) for the Sentry project.
 * @param {Array} integrations - An array of integrations to be used with Sentry.
 * @param {number} tracesSampleRate - The sampling rate for capturing transaction traces.
 * @param {Array} tracePropagationTargets - An array of URLs for which distributed tracing should be enabled.
 * @param {number} replaysSessionSampleRate - The sampling rate for capturing session replays.
 * @param {number} replaysOnErrorSampleRate - The sampling rate for capturing session replays on error.
 *
 * @format */

import * as Sentry from "@sentry/react";

const environment = import.meta.env.MODE;
const isDevelopment = environment === "development";

// Log where we are
console.log(
	"Observability is",
	isDevelopment ? "disabled on development." : "enabled on production."
);

// Initialize Sentry
Sentry.init({
	enabled: !isDevelopment,
	dsn: "https://581ca72a6a02890472d17541ecb447c1@o4506745847742464.ingest.sentry.io/4506746257014784",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring - Capture 100% of the transaction
	tracesSampleRate: 1.0,

	// Session Replay
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
