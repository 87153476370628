/** @format */
import axios from "axios";
import * as geotiff from "geotiff";
import * as geokeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";

export async function downloadGeoTIFF(url, apiKey) {
	// Adjust the URL to include the API key if necessary
	const solarUrl = url.includes("solar.googleapis.com")
		? `${url}&key=${apiKey}`
		: url;

	// Use Axios to fetch the data as an ArrayBuffer
	const response = await axios.get(solarUrl, {
		responseType: "arraybuffer",
	});
	if (response.status !== 200) {
		throw new Error(`Failed to download GeoTIFF: ${url}`);
	}

	// Process the ArrayBuffer with geotiff.js
	const tiff = await geotiff.fromArrayBuffer(response.data);
	const image = await tiff.getImage();
	const rasters = await image.readRasters();

	// Reproject the bounding box into lat/lon coordinates using proj4
	const geoKeys = image.getGeoKeys();
	const projObj = geokeysToProj4.toProj4(geoKeys);
	const projection = proj4(projObj.proj4, "WGS84");
	const box = image.getBoundingBox();
	const sw = projection.forward({ x: box[0], y: box[1] });
	const ne = projection.forward({ x: box[2], y: box[3] });

	// Map the raster data to a more accessible format
	const rastersMapped = rasters.map((raster) => Array.from(raster));

	const finalResponse = {
		width: image.getWidth(),
		height: image.getHeight(),
		rasters: rastersMapped,
		bounds: { north: ne.y, south: sw.y, east: ne.x, west: sw.x },
	};

	// console.log(finalResponse, "~133");

	return finalResponse;
}
