/**
 * Firebase configuration and initialization module.
 *
 * @format
 * @module firebase
 */

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getAuth, GoogleAuthProvider, signOut } from "firebase/auth";


import development from "./config/development.json" assert { type: "json" };
import production from "./config/production.json" assert { type: "json" };

const config = import.meta.env.MODE === "production" ? production : development;
const app = initializeApp(config);

const db = initializeFirestore(app, {
	ignoreUndefinedProperties: true,
});

const storage = getStorage(app);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { analytics, provider, auth, app, db, storage, signOut }; // auth,