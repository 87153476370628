/** @format */
import axios from "axios";

// Step 3: Fetch solar data layers based on the building's location
export async function getLayers({ center, apiKey }) {
	try {
		const radiusMeters = 30; // Example radius
		const dataLayersResp = await axios.get(
			`https://solar.googleapis.com/v1/dataLayers:get`,
			{
				params: {
					"location.latitude": center.latitude.toFixed(5),
					"location.longitude": center.longitude.toFixed(5),
					radius_meters: radiusMeters.toString(),
					key: apiKey,
				},
			}
		);

		// console.log("Data Layers Response:", dataLayersResp.data.rgbUrl);

		return dataLayersResp.data;
	} catch (error) {
		console.error("Error in processing:", error);
	}
}
