/** @format */

import axios from "axios";
import {
	deleteDoc,
	doc,
	onSnapshot,
	serverTimestamp,
	setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "firemade";
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { assistantId, prompts } from "safersolar";
import { getProjectName } from "../utils/utils";
import Results from "./Results";
import "./page.css";
import { getAuth } from "firebase/auth";
import { useParams, useNavigate } from 'react-router-dom';

// This will reset the chat
import { resetChat } from "chat";

const Page = () => {

	const { documentId } = useParams();

	// This will keep the user on track and the file on record
	const [projectName, setProjectName] = useState("");

	// This will keep the user on track and the file on record
	const [sessionId, setSessionId] = useState(null);

	// When we're working
	const [working, setWorking] = useState(false);

	//When to show progressbar
	const [showProgressBar, setShowProgressBar] = useState(false);

	// When we're working
	const [error, setError] = useState(false);

	// The results of the submissions
	const [submission, setSubmission] = useState(null);

	// Hook to get query string
	const location = useLocation();

	// Function to parse query string
	const getQueryStringParam = (param) => {
		const queryParams = new URLSearchParams(location.search);
		return queryParams.get(param);
	};

	// Listen for changes to the session id. If the session id changes, update the session id in the state.
	useEffect(() => {
		// Get the project name
		const projectName = getProjectName();

		// Set the project name
		setProjectName(projectName);

		// Check if sessionId is present in the query string
		// const querySessionId = getQueryStringParam("sessionId");

		// console.log(documentId);


		let currentSessionId = documentId || Cookies.get("sessionId");

		// set the session id from a querystring if it exists
		// let currentSessionId = querySessionId || Cookies.get("sessionId");

		// Check if we have one yet.
		if (!currentSessionId) {
			currentSessionId = nanoid();
			Cookies.set("sessionId", currentSessionId, {
				expires: 7,
			});
		}

		// Set the session id
		setSessionId(currentSessionId);

		// Remove document listener when done
		const unsubscribe = onSnapshot(
			doc(db, "submissions", currentSessionId),
			(doc) => {
				if (doc.exists()) {
					const data = doc.data();
					if (data.status === "failed") setError(true);
					if (data.status === "started") {
						setShowProgressBar(true);
						setWorking(true);
					}
					if (data.status === "complete") {
						setShowProgressBar(false);
						setWorking(false);
					}
					setSubmission(data || null);
				} else {
					// Document does not exist, which means no results have been set
					setShowProgressBar(false);
					setSubmission(null);
					setWorking(false);
					setError(false);
				}
			}
		);

		return () => {
			unsubscribe();
		};
	}, [sessionId, documentId]);

	const [userId, setUserId] = useState(null);

	useEffect(() => {
		// ...

		// Get the current user's ID
		const auth = getAuth();
		const user = auth.currentUser;
		const currentUserId = user ? user.uid : null;
		setUserId(currentUserId);

		// ...
	}, [sessionId]);

	/**
	 * Handles the form submission.
	 * @param {Event} event - The form submission event.
	 * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
	 */
	const handleUpload = async (file) => {
		if (!file) {
			alert("Please select a file to upload");
			return;
		}

		// Set that we're working
		setWorking(true);

		try {

			console.log('This is the user\'s ID: ', userId);

			// Set the path
			const path = `${projectName}/${sessionId}.pdf`;

			// Create a file reference
			const fileRef = ref(storage, path);

			// Upload the file
			await uploadBytes(fileRef, file);

			// Get the download URL
			const url = await getDownloadURL(fileRef);

			// Create a document
			await setDoc(doc(db, "submissions", sessionId), {
				result: {},
				messages: [],
				project: projectName,
				file: file.name,
				path,
				url,
				userId,
				status: "uploading",
				attempts: 1,
				createdAt: serverTimestamp(),
				updatedAt: serverTimestamp(),
			});

			// Submit to the ai
			await submitToAI(file, userId);

			setWorking(false);
		} catch (error) {
			setError(true);
			setWorking(false);
		}
	};

	/**
	 * Got to the results page is functionalized to allow "continuation"
	 * of a started process for edgecases like errors,etd.
	 *
	 */
	const submitToAI = async (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = async function () {
			const base64data = reader.result;

			const data = {
				file: base64data,
				projectName,
				sessionId,
				userId,
				assistantId,
				prompts,
			};

			/*
			Asynch presents challenges with dealing with service failures.
			As such, create a long-poll (3 minutes) to avoid creating an
			error on timeout. Otherwise, create an error at the service
			level. 
			*/
			axios
				.post("/api/analyze", data, { timeout: 60 * 1000 * 3 })
				.then(() => {
					setWorking(false);
					setError(false);
				})
				.catch(() => {
					setError(true);
					setWorking(false);
				});
		};
	};

	const navigate = useNavigate();

	/**
	 * Removes the submission document based on the sessionId.
	 */
	const removeSubmission = async () => {

		if (!sessionId) {
			console.error("Session ID is not set.");
			return;
		}

		// Set that we're working
		setWorking(true);

		try {
			resetSession();
			// await deleteDoc(doc(db, "submissions", sessionId));
			// Perform any additional cleanup or state updates as needed
			navigate('/');
		} catch (error) {
			console.error("Error removing document:", error);
		}

		// Reset the chat
		resetChat(sessionId);

		// Set that we're not working any longer
		setWorking(false);

		// Clear up any errors
		setError(false);
	};

	/**
	 * Sends the failure email to the server.
	 * @param {string} email - The email address to send the failure to.
	 * @returns {Promise<void>} - A promise that resolves when the email is sent successfully.
	 */
	const emailOnError = async (email) => {
		// Send the failure to make
		return axios.post("/api/email", {
			email,
			sessionId,
		});
	};

	/**
	 * Sends the failure address to the server.
	 * @param {string} address - The address to send the failure to.
	 * @returns {Promise<void>} - A promise that resolves when the address is sent successfully.
	 */
	const addressOnError = async (address) => {
		// Send the failure to make
		return axios.post("/api/address", {
			address,
			sessionId,
		});
	};

	/**
	 * Resets the session by removing the sessionId cookie, clearing the session ID state, and reloading the page.
	 */
	const resetSession = () => {
		// Remove the cookie (to start a new session)
		Cookies.remove("sessionId");

		// Clear the session ID
		setSessionId("");

		// All submission information should be removed
		setSubmission(null);

		// Remove any lingering errors
		setError(false);
	};

	// This should be replaced with a loading gif
	if (!sessionId || !projectName) return null;
	return (
		<Results
			projectName={projectName}
			sessionId={sessionId}
			submission={submission}
			working={working}
			setWorking={setWorking}
			showProgressBar={showProgressBar}
			handleUpload={handleUpload}
			removeSubmission={removeSubmission}
			submitToAI={submitToAI}
			emailOnError={emailOnError}
			addressOnError={addressOnError}
			resetSession={resetSession}
			error={error}
		/>
	);
};

export default Page;
