import React from 'react';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-semibold text-center mb-4">Privacy Policy</h1>
      <p className="text-md text-gray-600 mb-2">Effective Date: February 9th, 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Welcome to SaferSolar.ai</h2>
        <p className="text-gray-700 mb-3">
          Welcome to SaferSolar.ai, a service designed to provide you with automated reviews of solar proposals through PDF uploads. This Privacy Policy outlines how we collect, use, protect, and share information gathered about you in connection with your use of our service, as well as your rights regarding your personal information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Information Collection and Use</h2>
        <p className="text-gray-700 mb-3">
          When you use SaferSolar.ai, we may collect information that you provide directly, such as your email address, to facilitate the service. We also collect information about your uploaded PDF solar proposal to perform the automatic review.
        </p>
        <ul className="list-disc ml-4 text-gray-700">
          <li>Provide, operate, and maintain our services</li>
          <li>Improve, personalize, and expand our services</li>
          <li>Understand and analyze how you use our services</li>
          <li>Develop new products, services, features, and functionality</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Information Sharing and Disclosure</h2>
        <p className="text-gray-700 mb-3">
          We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Data Security</h2>
        <p className="text-gray-700">
          We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, transaction information, and data stored on our site.
        </p>
      </section>

      {/* Liability Disclaimer Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Liability Disclaimer</h2>
        <p className="text-gray-700">
          SaferSolar.ai assumes no liability for the accuracy, reliability, or completeness of the information provided through the service. We are not responsible for any decisions made based on the results of the review provided by our service. The review is provided for informational purposes only and should not be considered as professional advice.
        </p>
      </section>

      {/* No Responsibility for External Links Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">No Responsibility for External Links</h2>
        <p className="text-gray-700">
          Our Service may contain links to external websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms of service of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites, products, or services.
        </p>
      </section>

      {/* Changes to This Privacy Policy Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Changes to This Privacy Policy</h2>
        <p className="text-gray-700">
          We reserve the right to update or change our Privacy Policy at any time. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
      </section>

      {/* Your Acceptance of These Terms Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Your Acceptance of These Terms</h2>
        <p className="text-gray-700">
          By using this Service, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Service. Your continued use of the Service following the posting of changes to this policy will be deemed your acceptance of those changes.
        </p>
      </section>

      {/* Contact Us Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at dane@safersolar.ai.
        </p>
      </section>
      <div className="container mx-auto mt-20 max-w-5xl">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
