import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { auth, signOut } from "/packages/firemade/src/index.js";

const Footer = () => {

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('Sign out successful');
    }).catch((error) => {
      // An error happened.
      console.error('Sign out error', error);
    });
  }

  return (
    <div className="self-center flex w-full max-w-[1272px] justify-between gap-5 px-5 pt-20 items-start max-md:max-w-full max-md:flex-wrap max-md:mt-10">
      <div className="text-neutral-700 text-center text-sm font-medium leading-6">
        <Link to="/">SaferSolar</Link>
      </div>
      <div className="self-stretch flex items-stretch justify-between gap-5">
        <div className="text-neutral-700 text-center text-sm leading-6 self-start">
          <Link to="/About-Us">About Us</Link>
        </div>
        <div className="text-neutral-700 text-center text-sm leading-6">
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </div>
        <div className="text-neutral-700 text-center text-sm leading-6 self-start">
          <Link to="/TermsOfService">Terms of Service</Link>
        </div>
        {auth.currentUser && (
          <div className="text-neutral-700 text-center text-sm leading-6 self-start">
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Footer;