import React from "react";
import { signInWithGoogle } from "../services/authService";
import googleIcon from '../assets/images/goog.svg';
import Footer from "./Footer";
import Logo from "../assets/images/logo2.svg";

const logoHeader = () => {
  return (
    <div className="items-center self-center flex gap-1 mt-6 px-5">
      <img loading="lazy" src={Logo} alt="Logo" className="aspect-square object-contain object-center w-7 fill-yellow-500 overflow-hidden shrink-0 max-w-full my-auto" />
      <div className="cursor-pointer">
        <div className="flex items-center">
          <div className="text-zinc-800 text-center text-xl font-medium leading-8 self-stretch grow whitespace-nowrap">
            SaferSolar
          </div>
          <div className="text-beta text-center text-xs leading-5 self-stretch grow whitespace-nowrap text-gray-600 mt-1 beta-text">
            Beta
          </div>
        </div>
      </div>
    </div>
  );
};

const tagLine = () => {
  return (
    <>
      <div className="self-center text-neutral-700 text-3xl font-semibold leading-10 w-[432px] max-w-full mt-8 text-center">
        Don't get sun <em>burned</em>.
      </div>
      <div className="self-center text-neutral-700 text-base leading-7 w-[432px] max-w-full mt-2 text-center">
        Upload your solar proposal and receive a review in 60 seconds.
      </div>
    </>
  );
};

function SignIn() {
  return (
    <div className="bg-white flex flex-col p-6 pb-12 min-h-screen">
      <div className="p-8 rounded-lg flex flex-col items-center flex-grow justify-center">
        {logoHeader()}
        {tagLine()}
        <div className="text-center mt-8">
          <button
            className="bg-[#333333] text-white px-7 py-4 border border-[#757B7D] rounded-lg cursor-pointer inline-flex justify-center items-center transition-colors duration-200 text-sm font-medium gap-2.5 w-[350px] h-[33px] hover:bg-[#357ae8] focus:outline-none focus:shadow-[0_0_0_3px_rgba(66,133,244,0.5)]"
            onClick={signInWithGoogle}
          >
            <img src={googleIcon} alt="Google sign-in" className="w-6 h-6" />
            Continue with Google
          </button>
          <div>
            <p className="text-gray-500 text-xs text-center p-4 box-border w-full">
              By clicking "Sign in with Google" you agree to our Terms of Use and Privacy policy
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignIn;