/** @format */
import axios from "axios";

// Step 2: Fetch the closest building insights
export async function getBuilding({ lat, lng, apiKey }) {
	try {
		const buildingInsightsResp = await axios.get(
			`https://solar.googleapis.com/v1/buildingInsights:findClosest`,
			{
				params: {
					"location.latitude": lat.toFixed(5),
					"location.longitude": lng.toFixed(5),
					key: apiKey,
				},
			}
		);

		// console.log("Building Insights:", buildingInsightsResp.data);

		// get the data
		return buildingInsightsResp.data;
	} catch (error) {
		console.error("Error in processing:", error);
	}
}
