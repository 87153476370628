import React, { useState, useRef } from "react";

//UTILS
import { formatNumber, formatPrice } from "../utils/utils";

//UI
import Skeleton from "../ui/Skeleton";
import { mockdata } from "../Page/mockdata";

const Tooltip = ({ children, onMouseLeave }) => {
  return (
    <div className="absolute top-[7rem] left-1/2 transform -translate-x-1/2 ml-15 p-10 flex items-center bg-white text-grey-800 shadow-2xl rounded-md w-[600px] z-10"
         onMouseEnter={() => onMouseLeave(false)}
         onMouseLeave={() => onMouseLeave(true)}
    >
      {children}
    </div>
  );
};


const OverviewInfo = ({ data, estimatedAmounts }) => {
  const { estimatedPrice, estimatedYield } = estimatedAmounts || {};
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const hideTooltipTimeout = useRef(null);

  const isPriceAboveEstimate = data["GrossPrice"] > data.MaxPrice;
  const isYieldAboveEstimate = data["QuotedYield"] > data.EstimatedYield;
  const yearsROI = Math.round(formatNumber((data.GrossPrice * .7) / (data.EstimatedYield * data.ElectricityRate / 100)));
  const estimatedMonthlyYieldDollars = data.EstimatedYield * data.ElectricityRate / 100 / 12;


  //tooltips
  const handleMouseEnter = index => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }
    setHoveredIndex(index);
    setTooltipVisible(true);
  };

  const handleMouseLeave = (shouldHide) => {
    if (shouldHide) {
      // Set a delay before hiding the tooltip to allow moving to the tooltip
      hideTooltipTimeout.current = setTimeout(() => {
        setTooltipVisible(false);
        setHoveredIndex(null);
      }, 100);
    } else {
      if (hideTooltipTimeout.current) {
        clearTimeout(hideTooltipTimeout.current);
        hideTooltipTimeout.current = null;
      }
    }
  };

  //PRICE
  const getPrice = () => {
    const color = isPriceAboveEstimate ? "#FF006B" : "#00E031";
    const delta = isPriceAboveEstimate ? " ↑" : "✓";
    if (data && data?.GrossPrice) {
      return (
        <div> {formatPrice(data["GrossPrice"])}
          <sup style={{ color: color }}>{delta}</sup>
        </div>
      );
    }

    else return null;
  };
  const getPriceDifference = () => {
    if (isPriceAboveEstimate) {
      return (
        // <div
        //   style={{ color: "#FF006B" }}
        //   className="text-l font-semibold leading-10"
        // >
        //   <p className="leading-none">{estimatedPrice > 0 ? `${formatPrice(estimatedPrice)}` : null}</p>
        //   <p className="leading-tight"><span className="text-neutral-700">too high</span></p>
        // </div>
        " "
      );
    }
    return "none";
  };
  const getGrossPriceTooltip = () => {

    let summary = "";

    if (data.GrossPrice > data.MaxPrice) {
      summary = (
        <p className="m-5 text-neutral-700">This system costs
          <span className="text-hot-pink font-bold"> {formatPrice(data.GrossPrice - data.MaxPrice)} </span>
          MORE than the expected maximum price.</p>
      );
    } else if (data.GrossPrice === data.MaxPrice) {
      summary = (
        <p className="m-5 text-neutral-700">The price for your system matches the maximum size we expect for a system this size. </p>)
    }
    return (
      <div className="m-5">
        <h1 className="text-xl font-bold text-center text-neutral-700">Solar Price</h1>

        <div>{summary}</div>


        <div className="grid grid-cols-2 pt-5 justify-items-center">

          <div className="font-bold text-neutral-700 pb-4">Quoted Price</div>
          <div className="font-bold text-neutral-700 pb-4">Expected Max Price*</div>

          <div className="text-neutral-700 pb-8">{formatPrice(data.GrossPrice)}</div>
          <div className="text-neutral-700 pb-8"> {formatPrice(data.MaxPrice)}</div>

          <div className="font-bold pb-4 text-neutral-700">Quoted PPW</div>
          <div><span className="font-bold pb-4 text-neutral-700">Avg PPW </span> in {data.fullStateName} (+20%)</div>

          <div className="pb-4 text-neutral-700">${data.PPW} / watt</div>
          <div className="pb-4 text-neutral-700">${(data.StatePrice * 1.2).toFixed(2)} / watt</div>

        </div>

        <p className="text-xs mt-4 text-center">
          *Expected Max Price based on the Average Price Per Watt in {data.fullStateName} of ${data.StatePrice} per watt plus 20% allowance.
        </p>
      </div >
    );
  }

  //YIELD
  const getYield = () => {
    const color = isYieldAboveEstimate ? "#FF006B" : "#00B140";
    const delta = isYieldAboveEstimate ? "↑" : "↑";
    if (data && data?.QuotedYield > 0 && data?.monthlyYieldDollars > 0) {
      // const monthlyYieldDollars = (data.QuotedYield * (data.ElectricityRate / 100 / 12)).toFixed(2);
      return (

        <div>
          {`${formatNumber(data.QuotedYield)} kWh`}

          <sup style={{ color: color }}>{delta}</sup></div>
      )
    }
    // <div
    //   onMouseEnter={() => setHoveredIndex(1)} // Set hoveredIndex to 1 when hovering over yield
    //   onMouseLeave={() => setHoveredIndex(null)}
    // >
    //   {hoveredIndex === 1 ? /* Render alternative value when hovered */ (

    //     <div>{`${formatNumber(data.QuotedYield)} kWh`}</div>

    //   ) : /* Render original monthlyYield when not hovered */ (
    //     <div>
    //       {`${formatNumber(data.monthlyYieldDollars)}/mo`}

    //       <sup style={{ color: color }}>{delta}</sup></div>
    //   )}
    // </div>
    //   );
    // }
    return null;
  };
  const getYieldDifference = () => {

    // const absoluteYield = Math.abs(estimatedYield);
    if (!isYieldAboveEstimate && data?.monthlyYieldDollars) {
      return (" "
        //     <div style={{ color: "#FF006B" }} className="text-1 font-semibold leading-10">

        //       {estimatedYield || estimatedYield < 0
        //         ? `▼ ${formatNumber(absoluteYield)} kWh`
        //         : null}
        //     </div>
      );
    }

    if (isYieldAboveEstimate && data?.monthlyYieldDollars) {

      // const absoluteYieldDollars = Math.abs(absoluteYield * (data.ElectricityRate / 100 / 12)).toFixed(2);

      return (
        //   <div style={{ color: "#00E031" }} className="text-1 font-semibold leading-10"
        //     onMouseEnter={() => setHoveredIndex(1)} // Set hoveredIndex to 1 when hovering over yield
        //     onMouseLeave={() => setHoveredIndex(null)}
        //   >
        //     {hoveredIndex === 1 ? /* Render alternative value when hovered */ (

        //       <div>{estimatedYield || estimatedYield > 0 ? `${formatNumber(absoluteYield)} kWh too low` : null}</div>

        //     ) : /* Render original monthlyYield when not hovered */ (
        //       <div>{estimatedYield || estimatedYield > 0 ? `$${formatNumber(absoluteYieldDollars)}/mo too low` : null}</div>
        //     )}

        " "

        //   </div>
      );
    }

    return "none";
  };

  const getYieldToolTip = () => {
    if (data && data?.ElectricityRate > 0 && data?.EstimatedYield > 0 && data?.GrossPrice > 0) {
      let summary = "";

      if (data.QuotedYield > data.EstimatedYield) {
        summary = (
          <p className="m-5 text-neutral-700">We estimate your system will generate
            <span className="text-hot-pink font-bold"> {formatNumber(data.QuotedYield - data.EstimatedYield)} kWh </span>
            less than your proposal does.</p>
        )
      }
      else if (data.QuotedYield < data.EstimatedYield) {
        summary = (
          <p className="m-5  text-neutral-700">We estimate your system will generate <span className="text-custom-green font-bold">{formatNumber(Math.abs(data.QuotedYield - data.EstimatedYield))} </span> more kWh per year than your proposal does.</p>)
      }
      else if (data.QuotedYield === data.EstimateYield) {
        summary = (
          <p className="m-5 text-neutral-700">The yield estimate for your system matches what we expect for a system this size. </p>)
      }
      return (
        <div>
          <div className="relative h-0">
          </div>
          <div>

            <h1 className="text-xl font-bold mb-10 text-center text-neutral-700">Annual Energy Production</h1>

            <div>{summary}</div>

            <div className="grid grid-cols-2 justify-items-center pt-5">

              <div className="font-bold text-neutral-700 pb-4">Quoted Yield</div>
              <div className="font-bold text-neutral-700 pb-4">Expected Yield</div>

              <div className="text-neutral-700 pb-8">{formatNumber(data.QuotedYield)} kWh</div>
              <div className="text-neutral-700 pb-8">{formatNumber(data.EstimatedYield)} kWh*</div>

              <div className="font-bold text-neutral-700 pb-4">Savings</div>
              <div className="font-bold text-neutral-700 pb-4">Expected Savings</div>

              <div className="text-neutral-700 pb-8"> <span className="font-bold text-custom-green">{formatPrice(data.QuotedYield * data.ElectricityRate / 100)} /year</span>**</div>

              <div className="text-neutral-700 pb-8"> <span className="font-bold text-custom-green">{formatPrice(estimatedMonthlyYieldDollars * 12)} /year</span>**</div>

              {/* <div></div>
              <div className="text-neutral-700 pb-8">${formatNumber(data.QuotedYield * data.ElectricityRate / 100 / 12)}/month</div>
              <div className="text-neutral-700 pb-8">{formatPrice(estimatedMonthlyYieldDollars)} /month</div>
              <div></div> */}

            </div>

            <div className="text-center col-span-4 pb-8">

              <p className="text-xs mt-8">*Yield estimate is based on NREL's <a href="https://pvwatts.nrel.gov/" target="_blank" className="underline">PVWatts Calculator</a> using your address and the proposed system size ({data.SystemSize} kW).</p>

              <p className="text-xs mt-4">
                **Monthly savings is based on the average rate of electricity in {data.fullStateName} which is {data.ElectricityRate} cents per kWh. Actual savings may vary.
              </p>

            </div>
          </div>
        </div>
      );
    }
  };

  const getROI = () => {

    const color = (yearsROI > 10) ? "#FF006B" : "";
    const delta = (yearsROI > 10) ? " ↑" : "";

    if (data && data?.QuotedYield && data?.GrossPrice) {
      return (

        <div>{yearsROI} years <sup style={{ color: color }}>{delta}</sup></div>

      )
    }
  }

  const getROIDifference = () => {

    // if (yearsROI > 10)

    //   return (<div style={{ color: "#FF006B" }} className="text-1 font-semibold leading-10">
    //     {yearsROI - 10} years <span className="text-neutral-700">too high</span>
    //   </div>);


    // const absoluteYield = Math.abs(estimatedYield);
    // if (!isYieldAboveEstimate && data?.monthlyYieldDollars) {
    //   return (
    //     <div style={{ color: "#FF006B" }} className="text-1 font-semibold leading-10">

    //       {estimatedYield || estimatedYield < 0
    //         ? `(Math.round(${formatNumber((data.GrossPrice * .7) / (data.EstimatedYield * data.ElectricityRate / 100))})) - 10;`
    //         : null}
    //     </div>
    //   );
    // }
    return "none";
  }
  const getROITooltip = () => {
    return (
      <div>
        <div className="relative h-0">
          {/* <GoTriangleLeft className="text-gray-800 h-8 w-8 relative -top-[3px] -left-[32px]" /> */}
        </div>
        <div>
          <h1 className="text-xl font-bold mb-10 text-center text-neutral-700">Return On Investment</h1>

          <p className="pb-8  text-neutral-700">We estimate it will take <span className="text-hot-pink font-bold">{yearsROI} years</span> for this system's energy savings to equal the system's cost. This is compared to the industry average of <span className="font-bold"> 8 years</span>.</p>

          <div className="grid grid-cols-6 pb-4 justify-items-center">
            <div></div>
            <div className="font-bold underline">Quoted</div>
            <div></div>
            <div></div>
            <div className="font-bold underline">Expected</div>
          </div>

          <div className="grid grid-cols-4 w-full justify-items-center">

            <div><span className="font-bold text-neutral-700">System Cost: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.GrossPrice)}</span></div>
            <div><span className="font-bold text-neutral-700">System Cost: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.expectedPrice)}</span></div>


            <div><span className="font-bold text-neutral-700">Tax Credit: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.GrossPrice * .3)}*</span></div>
            <div><span className="font-bold text-neutral-700">Tax Credit: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.expectedPrice * .3)}*</span></div>


            <div></div>
            <div className="w-2/3 border-b border-neutral-700 pb-2"></div>
            <div></div>
            <div className="w-2/3 border-b border-neutral-700 pb-2"></div>


            <div><span className="font-bold text-neutral-700">Net Cost: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.GrossPrice * .7)}</span></div>
            <div><span className="font-bold text-neutral-700">Net Cost: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.expectedPrice * .7)}</span></div>
          </div>

          <div className="grid grid-cols-4 w-full justify-items-center pt-6">

            <div><span className="font-bold text-neutral-700">Annual Yield: </span></div>
            <div><span className="text-neutral-700">{formatNumber(data.QuotedYield)} kWh</span></div>
            <div><span className="font-bold text-neutral-700">Annual Yield: </span></div>
            <div><span className="text-neutral-700">{formatNumber(data.EstimatedYield)} kWh</span></div>


            <div><span className="font-bold text-neutral-700">Annual Benefit: </span></div>
            <div><span className="text-neutral-700">{formatPrice(data.QuotedYield * data.ElectricityRate / 100)}</span></div>
            <div><span className="font-bold text-neutral-700">Annual Benefit: </span></div>
            <div><span className="text-neutral-700">{formatPrice(estimatedMonthlyYieldDollars * 12)}</span></div>
          </div>

          <div className="grid grid-cols-4 w-full justify-items-center pt-6">


            <div><span className="font-bold text-neutral-700">Payback: </span></div>
            <div><span className="text-neutral-700">{yearsROI} years</span></div>
            <div><span className="font-bold text-neutral-700">Payback: </span></div>
            <div><span className="text-neutral-700">{Math.round((data.expectedPrice * .7) / (data.EstimatedYield * data.ElectricityRate / 100))} years</span></div>

          </div>

          <div className="pt-10 pb-10"></div>
        </div>

        <div className="text-center col-span-4 pb-8"><p className="text-xs mt-8">*Learn about the 30% <a href="https://www.irs.gov/credits-deductions/residential-clean-energy-credit" target="_blank" className="underline">Federal Solar Income Tax Credit</a>. Individuals must owe income tax to benefit. Speak with your accountant for more details.</p></div>


      </div >
    );
  }

  {/* <p className="text-xs">At this rate, your system would pay itself back in about <span className="font-bold">{Math.round(formatNumber((data.GrossPrice * .7) / (data.EstimatedYield * data.ElectricityRate / 100)))} years</span> assuming you use the 30% tax credit. We consider a normal payback to be 7-10 years.</p> <br></br> */ }


  const getOverviewInfo = () => {
    return [
      {
        name: "Price",
        value: getPrice(),
        tooltip: getGrossPriceTooltip(),
        priceDifference: getPriceDifference(),
      },
      {
        name: "Yield",
        value: getYield(),
        tooltip: getYieldToolTip(),
        priceDifference: getYieldDifference(),
      },
      {
        name: "Payback",
        value: getROI(),
        tooltip: getROITooltip(),
        priceDifference: getROIDifference(),
      }
    ];
  };

  const overviewInfo = getOverviewInfo();
  return (
      <div className="w-[800px] max-w-full px-7 mt-11 self-start max-md:mt-10">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="text-neutral-700 text-xl font-semibold leading-8 whitespace-nowrap max-md:mt-10">
            Overview
          </div>
          {overviewInfo.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-stretch w-[45%] ml-5 max-md:w-full max-md:ml-0 ${item.tooltip ? "cursor-pointer" : ""}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-col items-stretch w-full max-md:ml-0">
                <div className="flex flex-col items-stretch max-md:mt-10 ">
                  <div className="items-stretch flex justify-between gap-2">
                    <div className="relative group">
                      <div className="text-neutral-700 text-base font-semibold leading-[32px]">
                        {item.name}
                      </div>
                      {isTooltipVisible && hoveredIndex === index && item.tooltip && item.value && (
                        <Tooltip onMouseLeave={handleMouseLeave}>{item.tooltip}</Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="text-neutral-600 text-2xl font-semibold leading-10">
                    {item.value || <Skeleton />}
                  </div>
                  <div className="text-neutral-600 text-l font-semibold leading-10">
                    {item.priceDifference === "none" ? "" : item.priceDifference || <Skeleton />}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default OverviewInfo;
