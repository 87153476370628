import React, { useEffect, useState } from "react";
import Skeleton from "../ui/Skeleton";

// Create the index
import { removeStopwords } from "stopword";

import { db } from "firemade";

import { collection, getDocs, query, where } from "firebase/firestore";

//UTILS
import { calculateYearsSince } from "../utils/utils";

//ASSETS
import Greencheck from "../assets/images/greentick.svg";
import Greycross from "../assets/images/greycross.svg";
import Star from "../assets/images/star.svg";

const Tooltip = ({ text, showTooltip }) => {
  if (!text || !showTooltip) {
    return null;
  }

  return (
    <div className="absolute bottom-[1.5rem] left-[9rem] ml-2 p-3 flex items-center bg-gray-200 text-black rounded-md max-w-[200px] z-10">
      <p className="text-xs">{text}</p>
    </div>
  );
};

/**
 * Cleans a string by removing all numbers, punctuation, specific business entity designations,
 * converting to lowercase, and trimming spaces from both ends while maintaining internal spaces.
 * Finally converts it to an array for indexing.
 * @param {string} input The input string to be cleaned.
 * @return {string} The cleaned string.
 */
function createIndex(input) {
  try {
    // First, remove all numbers and punctuation, then convert to lowercase
    let cleaned = input
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, " ")
      .toLowerCase();

    // Remove specific words like llc, inc, incorporated, corp, corporation
    // The \b denotes a word boundary, ensuring we match these terms as whole words
    // The 'i' flag makes the replacement case-insensitive
    cleaned = cleaned.replace(
      /\b(llc|inc|incorporated|corp|corporation)\b/gi,
      ""
    );

    // Trim spaces from both ends of the string and replace multiple spaces with a single space
    cleaned = cleaned.trim().replace(/\s+/g, " ");

    // Remove all stopwords
    cleaned = removeStopwords(cleaned.split(" "));

    return cleaned.sort();
  } catch (e) {
    console.error(e);
    return [];
  }
}

function calculateConfidenceScore(searchTerms, documentTerms) {
  // Calculate the intersection of searchTerms and documentTerms
  const intersection = searchTerms.filter((term) =>
    documentTerms.includes(term)
  );

  // Calculate the total unique terms
  const totalUniqueTerms = new Set([...searchTerms, ...documentTerms]).size;

  // Calculate the confidence score as a percentage
  const score = (intersection.length / totalUniqueTerms) * 100;

  return score;
}

export const findBusinessByName = async (
  businessName = "",
  isElectric = false
) => {
  // console.log(solarBusinesses, createIndex("Solar Impact"), "~112");

  // Example preliminary filter based on simplified index
  const searchTerms = createIndex(businessName);

  // console.log(searchTerms);
  const collectionName = isElectric ? "electric_licenses" : "solar_licenses";
  const q = query(
    collection(db, collectionName),
    where("index", "array-contains-any", searchTerms)
  );

  const querySnapshot = await getDocs(q);

  // Check if the query returned any documents
  if (querySnapshot.empty) {
    console.log("No matching businesses.");
    return null;
  }

  let bestMatch = null;
  let highestScore = 0;

  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    const docIndex = docData.index || [];
    const score = calculateConfidenceScore(searchTerms, docIndex);

    // Update bestMatch if this document has a higher score than the current bestMatch
    if (score > highestScore) {
      bestMatch = { id: doc.id, ...docData, score }; // Include the score for reference
      highestScore = score;
    }
  });

  // Log the best match and its score
  if (bestMatch) {
    console.log(`Best match: ${bestMatch.id} with a score of ${highestScore}%`);
  } else {
    console.log("No suitable match found.");
  }

  if (bestMatch && highestScore > 70) {
    console.log(`Best match: ${bestMatch.id} with a score of ${highestScore}%`);
    return bestMatch;
  } else if (bestMatch) {
    console.log(` match found but with a score of ${highestScore}%`);
  } else {
    console.log("No suitable match found or highest score is not above 70.");
    return null;
  }
};

const isStateInFlorida = (address) => {
  const addressComponents = address?.split(/[,\s]+/) || [];
  for (const component of addressComponents) {
    if (component === "FL") {
      return true;
    }
  }

  return false;
};

const getContractorInfo = async (data, solarBusinessData) => {
  const businessName = data?.Contractor;
  let licensesValue = [{ name: "Solar", hasData: false }]; 
  let ageOfCompanyValue = null;

    if (solarBusinessData) {
    licensesValue = [
      {
        name: "Solar",
        hasData: solarBusinessData && Object.keys(solarBusinessData).length > 0,
                // TODO - 16/03/2024 - Unmute electrical logic when ready
        // {
        //   name: "Electric",
        //   hasData: Object.keys(electricBusinessData).length > 0,
        // },
        // {
        //   name: "Roofing",
        // },
      },
    ];
    const ageOfCompany = data?.Contractor ? calculateYearsSince(solarBusinessData?.effectiveDate) : null;
    ageOfCompanyValue = getAgeOfCompany(data?.Contractor, ageOfCompany, solarBusinessData, data?.ContractorAddress);
  }
  if (data?.Contractor) {
    const ageOfCompany = data?.Contractor ? calculateYearsSince(solarBusinessData?.effectiveDate) : null;
    ageOfCompanyValue = getAgeOfCompany(data?.Contractor, ageOfCompany, solarBusinessData, data?.ContractorAddress);
  }

  // TODO - 16/03/2024 - Unmute electrical logic when ready
  // const electricBusinessData = await findBusinessByName(businessName, true);

  const ratingImg = (
    <img
      loading="lazy"
      src={Star}
      className="aspect-[1.14] object-contain object-center w-4 fill-yellow-400 overflow-hidden shrink-0 max-w-full self-start"
      alt="Star"
    />
  );

  const contractorInfo = [
    {
      name: "Name",
      value: data["Contractor"] ? data["Contractor"] : null,
      tooltip: "",
    },
    {
      name: "Rating",
      value: data["rating"] ? `${data["rating"]}` : null,
      amountOfReviews: `(${data["amountOfReviews"]} Google Reviews)`,
      img: ratingImg,
      tooltip: "",
    },
    {
      name: "Licenses",
      hasData: !!solarBusinessData,
      hasBusinessName: businessName,
      state: solarBusinessData ? solarBusinessData?.state : null,
      tooltip: "",
      value: licensesValue,
    },
    {
      name: "Age of company",
      value: ageOfCompanyValue,
      tooltip: "",
    },
  ];

  return contractorInfo;
};

const getAgeOfCompany = (
  businessName,
  ageOfCompany,
  business,
  contractorAddress = ""
) => {
  const isInFlorida = isStateInFlorida(contractorAddress);

  if ((business?.state && business?.state !== "FL") || !isInFlorida) {
    return getStateError();
  }
  if (businessName && !business) {
    return "Not found.";
  }

  return ageOfCompany ? `${ageOfCompany} years` : null;
};

const getStateError = () => {
  return <div>Currently only available for Florida contractors.</div>;
};

const ContractorInfo = ({ data }) => {
  const [hoveredTitle, setHoveredTitle] = useState(null);
  const initialContractorInfo = [
  { name: "Name", value: null, tooltip: "" },
  { name: "Rating", value: null, amountOfReviews: null, img: null, tooltip: "" },
  {
    name: "Licenses",
    hasData: false,
    hasBusinessName: false,
    state: null,
    tooltip: "",
    value: [
      { name: "Solar", hasData: false },
      // When ready, you can add other licenses here
    ],
  },
  { name: "Age of company", value: null, tooltip: "" },
  ];
  const [contractorInfo, setContractorInfo] = useState(initialContractorInfo);

  const [solarBusiness, setSolarBusiness] = useState(null);

  const [temporaryData, setTemporaryData] = useState(null);
  const [debounceTimer, setDebounceTimer] = useState(null);


  useEffect(() => {
    const fetchAndSetSolarBusiness = async () => {
      if (!data || Object.keys(data).length === 0) {
        setContractorInfo(initialContractorInfo);
        setSolarBusiness(null);
        return;
      }

      const businessName = data?.Contractor;
      const fetchedSolarBusinessData = await findBusinessByName(businessName);
    
      setTemporaryData({ solarBusinessData: fetchedSolarBusinessData, contractorData: data });
    };

    fetchAndSetSolarBusiness();
  }, [data]);


  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(async () => {
      if (temporaryData) {
        setSolarBusiness(temporaryData.solarBusinessData);
        const tmpContractInfo = await getContractorInfo(temporaryData.contractorData, temporaryData.solarBusinessData);
        setContractorInfo(tmpContractInfo);
      }
    }, 500);

    setDebounceTimer(timer);
    return () => clearTimeout(timer);
  }, [temporaryData]);


  const isInFlorida = isStateInFlorida(data?.ContractorAddress)
  return (
    <div className="flex w-[800px] max-w-full flex-col px-7 mt-12 self-start max-md:mt-10">
      <div className="self-stretch max-md:max-w-full max-md:pr-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch max-md:w-[30%] md:min-w-[124px] max-md:w-full max-md:ml-0">
            <div className="text-neutral-700 text-xl font-semibold leading-8 whitespace-nowrap max-md:mt-10">
              Contractor
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[70%] ml-5 max-md:w-full max-md:ml-0">
            <div className="flex flex-col justify-start items-stretch max-md:mt-10 relative">
              {contractorInfo.map((info, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredTitle(index)}
                  onMouseLeave={() => setHoveredTitle(null)}
                  className={`relative ${info.tooltip ? "cursor-pointer" : ""}`}
                >
                  <div
                    className={`text-neutral-700 text-base font-semibold ${
                      index === 0 ? "leading-[32px]" : "mt-12 max-md:mt-10"
                    }`}
                  >
                    {info.name}
                    {hoveredTitle === index && info.tooltip && info.value && (
                      <Tooltip text={info.tooltip} showTooltip={true} />
                    )}
                  </div>
                  {info.name === "Rating" ? (
                    info.value ? (
                      <div className="flex items-center gap-2 mt-5">
                        {info.img}
                        <div className="text-neutral-700 text-base leading-3 my-auto">
                          {`${info.value} ${info.amountOfReviews}`}
                        </div>
                      </div>
                    ) : (
                      <Skeleton />
                    )
                  ) : info.name === "Licenses" ? (
                    !solarBusiness && !data?.Contractor ? (
                      <Skeleton />
                    ) : !isInFlorida ? (
                      getStateError()
                    ) : (
                      info.value.map((license, licenseIndex) => (
                        <div key={licenseIndex} className="flex items-center gap-2 mt-2.5">
                          <img
                            loading="lazy"
                            src={license.hasData ? Greencheck : Greycross}
                            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                            alt={license.hasData ? "Active License" : "Inactive License"}
                          />
                          <div className="text-neutral-700 text-base leading-6">
                            {license.name} License
                          </div>
                        </div>
                      ))
                    )
                  ) : (
                    <div className="text-neutral-700 text-base leading-6 mt-2">
                      {info.value ? info.value : <Skeleton />}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorInfo;
