import React, { useState } from "react";

import Skeleton from "../ui/Skeleton";

const Tooltip = ({ children }) => {
  return (
    <div className="absolute top-[-2.8rem] left-[12rem] ml-15 p-3 flex items-center bg-gray-800 text-white rounded-md w-[300px] z-10">
      {children}
    </div>
  );
};

const GearInfo = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const getGearData = () => {
    const panelTooltip =
      data.PanelDetails !== 'Not Found' ? (
        <div>
          <p className="text-xs">
            Solar panels are the device that converts sunlight into electricity
          </p>
          <p className="text-xs mt-2">
            This particular panel is a {data.PanelRating}, meaning that it is
            ranked very highly in durability, reliability, and yield.
          </p>
        </div>
      ) : null;

    return [
      {
        name: 'Panel',
        value: data['PanelDetails'],
        tooltip: panelTooltip,
      },
      {
        name: 'Inverters',
        value: data['InverterDetails'],
        tooltip: '',
      },
    ];
  };

  const gearData = getGearData();
  return (
    <div className="flex w-[800px] max-w-full flex-col px-7 mt-12 self-start max-md:mt-10">
      <div className="self-stretch max-md:max-w-full max-md:pr-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch max-md:w-[30%] md:min-w-[124px] max-md:w-full max-md:ml-0">
            <div className="text-neutral-700 text-xl font-semibold leading-8 whitespace-nowrap max-md:mt-10">
              Gear
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[70%] ml-5 max-md:w-full max-md:ml-0">
            {gearData.map((item, index) => (
              <div
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={`flex flex-col items-stretch max-md:w-full ${
                  item.tooltip ? "cursor-pointer" : ""
                }`}
              >
                <div className="text-neutral-700 text-base font-semibold leading-[32px]">
                  {item.name}
                </div>
                <div className="relative group mb-6">
                  <div className="text-warmGray-300 text-md font-semibold leading-8 whitespace-nowrap">
                    <span className="text-neutral-700 font-normal">
                      {item.value || <Skeleton />}
                    </span>
                  </div>
                  {hoveredIndex === index && item.tooltip && item.value && (
                    <Tooltip>{item.tooltip}</Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GearInfo;
