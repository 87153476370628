/** @format */

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

export const getProjectName = () => {
	const hostname = window.location.hostname;
	// Split hostname into parts
	const parts = hostname.split(".");
	// Remove the TLD. This works well for domains like 'example.com'
	if (parts.length > 2) {
		// Remove subdomain (anything before the 'domain.tld')
		return parts.slice(-2, -1)[0];
	}
	return parts[0] == "localhost" ? "safersolar" : parts[0];
};

export const formatNumber = (number) => {
	return number?.toLocaleString?.() ?? 0;
};

export const formatPrice = (price) => {
	// Convert to number if it's a string
	const numericPrice =
		typeof price === "string"
			? parseFloat(price.replace(/[^0-9.-]+/g, ""))
			: price;

	// Format as USD
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		// Optional: To display as a whole dollar amount without cents
		// minimumFractionDigits: 0,
		// maximumFractionDigits: 0,
	}).format(numericPrice);
};

export const downloadPDF = () => {
	const input = document.body;
	html2canvas(input).then((canvas) => {
		const imgData = canvas.toDataURL("image/jpeg", 0.75);

		// Create a new PDF instance and add the image
		const pdf = new jsPDF({
			orientation: "portrait",
			unit: "px",
			format: [canvas.width, canvas.height],
		});

		pdf.addImage(
			imgData,
			"JPEG",
			0,
			0,
			canvas.width,
			canvas.height,
			"",
			"SLOW"
		);

		// Save the PDF
		pdf.save("download.pdf");
	});
};

export const extractZipCode = (address) => {
	const zipCodeMatch = /\b\d{5}\b/.exec(address);
	return zipCodeMatch ? zipCodeMatch[0] : null;
};

export const calculateYearsSince = (dateString) => {
	const startDate = moment(dateString, "MM/DD/YYYY");
	const today = moment();
	return today.diff(startDate, "years") === 0
		? "1"
		: today.diff(startDate, "years");
};
