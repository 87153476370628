const getQuotedYield = (data) => {
  if (data?.QuotedYield > data?.EstimatedYield * 1.2) {
    return "quoted yield is bigger than expected yield";
  }
  return "It seems like your contractor expects this system to produce more energy than we think is reasonable. (See yield section above).";
};

const getQuotedPrice = (data) => {
  if (data?.GrossPrice > data?.EstimatedPrice * 1.2) {
    return "quoted price is bigger than expected price";
  }
  return "It seems like your contractor is charging more for this system than we think is reasonable. (See price section above).";
};

const labelMappings = {
  RoofPenetration: "Roof Penetration Warranty",
  InverterDetails: "Inverter",
  Workmanship: "Workmanship Warranty",
  PanelDetails: "Solar Panel Make/Model",
  CVC: "CVC",
};

const Todo = ({ data }) => {

  const todos = {
    PanelDetails: {
      point: "Ask your contractor which panel they'll be installing.",
    },
    InverterDetails: {
      point: "Find out the specific inverter brand and model.",
    },
    Contractor: {
      point: "Confirm the contractor's name.",
    },
    QuotedYield: {
      point: getQuotedYield(data),
    },
    RoofPenetration: {
      point: "Find out the roof penetration warranty being offered.",
    },
    Workmanship: {
      point: "Find out the workmanship warranty being offered.",
    },
    FinanceType: {
      point: "Confirm if this is a cash, finance, or lease deal.",
    },
    Scope: {
      point: "Confirm if this is solar only or if this includes additional scope.",
    },
    GrossPrice: {
      point: getQuotedPrice(data),
    },
    SystemSize: {
      point: "Confirm how many panels are being installed and what the rating is.",
    },
    Address: {
      point: "Get the contractor to add your address to the proposal or at least make sure it's in the contract.",
    },
    Name: {
      point: "Get the contractor to add your name to the proposal or at least ensure it's in the contract.",
    },
    ContractorAddress: {
      point: "Get the contractor to include their address in the proposal or at least ensure it's in the contract.",
    },
    PanelProductWarranty: {
      point: "Find out if the solar panel manufacturer offers a warranty for their panel.",
    },
    InverterWarranty: {
      point: "Find out if the inverter manufacturer offers a warranty for their inverter.",
    },
    ProductionWarranty: {
      point: "Find out if the solar panel manufacturer offers a production warranty for their panel.",
    },
    CVC: {
      point: "Your proposal should include the contractor's CVC solar license number."
    }
  };

  const actions = Object.entries(data)
    .filter(
      ([key, value]) =>
        value === "Not Found" ||
        value === "No Inverter Details found." ||
        value === "No Contractor Found"
    )
    .map(([key]) => ({
      label: labelMappings[key] || key.replace(/([A-Z])/g, " $1").trim(),
      point: todos[key]
        ? todos[key].point
        : `Action for ${key} is not specifically defined.`,
    }))


  return (
    <div className="todo-container bg-neutral-100 self-stretch flex flex-col items-stretch mt-11 pl-6 pr-6 py-6 rounded-lg max-md:max-w-full max-md:mt-10 max-md:px-5">
      <div className="flex w-[800px] max-w-full flex-col px-4 mt-6 self-start max-md:mt-10">
        <div className="self-stretch max-md:max-w-full max-md:pr-5">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch max-md:w-[30%] md:min-w-[124px] max-md:w-full max-md:ml-0">
              <div className="todo-text text-neutral-700 text-xl font-semibold">
                To do
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[70%] max-md:w-full max-md:ml-0">
              <div className="flex flex-col justify-start items-stretch max-md:mt-10">
                <div className="todo-text text-sm text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                  This is what we recommend you do based on your proposal:
                </div>
                <div className="todo-text text-neutral-700 text-base leading-7 w-[472px] max-w-full mt-4 mb-14 self-start max-md:mb-10">
                  <ul className="list-disc ml-5 text-sm leading-6">
                    <li>
                      Ensure that your roof is less than 10 years old and not in
                      need of repair or replacement
                    </li>
                    <li>
                      Check your eligibility for the{" "}
                      <a
                        href="https://www.energy.gov/sites/default/files/2021/02/f82/Guide%20to%20Federal%20Tax%20Credit%20for%20Residential%20Solar%20PV%20-%202021.pdf"
                        className="underline"
                        target="_blank"
                      >
                        {" "}
                        30% federal income tax credit
                      </a>
                    </li>
                    <li>
                      Verify whether the solar panels will be covered under your existing homeowner's insurance policy
                    </li>
                    <li>
                      Ask your contractor for a new proposal that includes the following important items:
                    </li>
                    <ul className="list-disc ml-5 text-sm leading-6 custom-bullet">
                      {actions.map((action, index) => (
                        <li key={index} className="mb-2 todo-text">
                          <span className="font-semibold">{action.label}:</span> {action.point}
                        </li>
                      ))}
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[186px] ml-5 max-md:w-full max-md:ml-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Todo;
