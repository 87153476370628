/** @format */

export function renderRGB(rgb, mask) {
	const canvas = document.createElement("canvas");
	canvas.width = mask ? mask.width : rgb.width;
	canvas.height = mask ? mask.height : rgb.height;

	const dw = rgb.width / canvas.width;
	const dh = rgb.height / canvas.height;

	const ctx = canvas.getContext("2d");
	const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

	for (let y = 0; y < canvas.height; y++) {
		for (let x = 0; x < canvas.width; x++) {
			const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
			const maskIdx = y * canvas.width + x;
			const imgIdx = y * canvas.width * 4 + x * 4;
			img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx];
			img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx];
			img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx];
			img.data[imgIdx + 3] = mask ? mask.rasters[0][maskIdx] * 180 : 100;
		}
	}

	ctx.putImageData(img, 0, 0);
	return canvas;
}

export function renderPalette({
	data,
	mask,
	colors = ["000000", "ffffff"],
	min = 0,
	max = 1,
	index = 0,
}) {
	const palette = createPalette(colors);
	const indices = data.rasters[index]
		.map((x) => normalize(x, max, min))
		.map((x) => Math.round(x * (palette.length - 1)));
	return renderRGB(
		{
			...data,
			rasters: [
				indices.map((i) => palette[i].r),
				indices.map((i) => palette[i].g),
				indices.map((i) => palette[i].b),
			],
		},
		mask
	);
}

export function createPalette(hexColors) {
	const rgb = hexColors.map(colorToRGB);
	const size = 256;
	const step = (rgb.length - 1) / (size - 1);
	return Array(size)
		.fill(0)
		.map((_, i) => {
			const index = i * step;
			const lower = Math.floor(index);
			const upper = Math.ceil(index);
			return {
				r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
				g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
				b: lerp(rgb[lower].b, rgb[upper].b, index - lower),
			};
		});
}

function colorToRGB(color) {
	const hex = color.startsWith("#") ? color.slice(1) : color;
	return {
		r: parseInt(hex.substring(0, 2), 16),
		g: parseInt(hex.substring(2, 4), 16),
		b: parseInt(hex.substring(4, 6), 16),
	};
}

function normalize(x, max = 1, min = 0) {
	return clamp((x - min) / (max - min), 0, 1);
}

function lerp(x, y, t) {
	return x + t * (y - x);
}

function clamp(x, min, max) {
	return Math.min(Math.max(x, min), max);
}

function rgbToColor({ r, g, b }) {
	const f = (x) => {
		const hex = Math.round(x).toString(16);
		return hex.length == 1 ? `0${hex}` : hex;
	};
	return `#${f(r)}${f(g)}${f(b)}`;
}
