/** @format */

import {
	binaryPalette,
	ironPalette,
	rainbowPalette,
	sunlightPalette,
} from "./colors";
import { downloadGeoTIFF } from "./download";
import { renderPalette, renderRGB } from "./visualize";

export async function getLayer({ layerId, urls, apiKey }) {
	const get = {
		mask: async () => {
			const mask = await downloadGeoTIFF(urls.maskUrl, apiKey);
			const colors = binaryPalette;
			return {
				id: layerId,
				bounds: mask.bounds,
				palette: {
					colors: colors,
					min: "No roof",
					max: "Roof",
				},
				render: (showRoofOnly) => [
					renderPalette({
						data: mask,
						mask: showRoofOnly ? mask : undefined,
						colors: colors,
					}),
				],
			};
		},
		dsm: async () => {
			const [mask, data] = await Promise.all([
				downloadGeoTIFF(urls.maskUrl, apiKey),
				downloadGeoTIFF(urls.dsmUrl, apiKey),
			]);
			const sortedValues = Array.from(data.rasters[0]).sort((x, y) => x - y);
			const minValue = sortedValues[0];
			const maxValue = sortedValues.slice(-1)[0];
			const colors = rainbowPalette;
			return {
				id: layerId,
				bounds: mask.bounds,
				palette: {
					colors: colors,
					min: `${minValue.toFixed(1)} m`,
					max: `${maxValue.toFixed(1)} m`,
				},
				render: (showRoofOnly) => [
					renderPalette({
						data: data,
						mask: showRoofOnly ? mask : undefined,
						colors: colors,
						min: sortedValues[0],
						max: sortedValues.slice(-1)[0],
					}),
				],
			};
		},
		rgb: async () => {
			const [mask, data] = await Promise.all([
				downloadGeoTIFF(urls.maskUrl, apiKey),
				downloadGeoTIFF(urls.rgbUrl, apiKey),
			]);
			return {
				id: layerId,
				bounds: mask.bounds,
				render: (showRoofOnly) => [
					renderRGB(data, showRoofOnly ? mask : undefined),
				],
			};
		},
		annualFlux: async () => {
			const [mask, data] = await Promise.all([
				downloadGeoTIFF(urls.maskUrl, apiKey),
				downloadGeoTIFF(urls.annualFluxUrl, apiKey),
			]);
			const colors = ironPalette;
			return {
				id: layerId,
				bounds: mask.bounds,
				palette: {
					colors: colors,
					min: "Shady",
					max: "Sunny",
				},
				render: (showRoofOnly) => [
					renderPalette({
						data: data,
						mask: showRoofOnly ? mask : undefined,
						colors: colors,
						min: 0,
						max: 1800,
					}),
				],
			};
		},
		monthlyFlux: async () => {
			const [mask, data] = await Promise.all([
				downloadGeoTIFF(urls.maskUrl, apiKey),
				downloadGeoTIFF(urls.monthlyFluxUrl, apiKey),
			]);
			const colors = ironPalette;
			return {
				id: layerId,
				bounds: mask.bounds,
				palette: {
					colors: colors,
					min: "Shady",
					max: "Sunny",
				},
				render: (showRoofOnly) =>
					[...Array(12).keys()].map((month) =>
						renderPalette({
							data: data,
							mask: showRoofOnly ? mask : undefined,
							colors: colors,
							min: 0,
							max: 200,
							index: month,
						})
					),
			};
		},
		hourlyShade: async () => {
			const [mask, ...months] = await Promise.all([
				downloadGeoTIFF(urls.maskUrl, apiKey),
				...urls.hourlyShadeUrls.map((url) => downloadGeoTIFF(url, apiKey)),
			]);
			const colors = sunlightPalette;
			return {
				id: layerId,
				bounds: mask.bounds,
				palette: {
					colors: colors,
					min: "Shade",
					max: "Sun",
				},
				render: (showRoofOnly, month, day) =>
					[...Array(24).keys()].map((hour) =>
						renderPalette({
							data: {
								...months[month],
								rasters: mask.rasters.map((values) =>
									values.map((x) => x & (1 << (day - 1)))
								),
							},
							mask: showRoofOnly ? mask : undefined,
							colors: colors,
							min: 0,
							max: 1,
							index: hour,
						})
					),
			};
		},
	};

	try {
		let response = await get[layerId]();
		return response;
	} catch (e) {
		console.error(`Error getting layer: ${layerId}\n`, e);
		throw e;
	}
}
