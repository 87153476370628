import React, { useState } from "react";
import Skeleton from "../ui/Skeleton";

const Tooltip = ({ text, showTooltip, hasValue }) => {
  if (!text || !showTooltip || !hasValue) {
    return null;
  }

  return (
    <div className="absolute left-[250px] ml-2 p-3 flex items-center bg-gray-800 text-white rounded-md max-w-[300px]">
      <p className="text-xs">{text}</p>
    </div>
  );
};

const getColoredLetter = (warrantyPeriod) => {
  if (warrantyPeriod === null) {
    return {
      colorClass: "text-na-grey",
      grade: "Not Found",
    };
  } else if (warrantyPeriod === 0) {
    return {
      colorClass: "text-na-grey",
      grade: "Not Found",
    };
  }

  let colorClass, grade;
  if (warrantyPeriod >= 25) {
    colorClass = "text-a-green text-xl";
    grade = "A";
  } else if (warrantyPeriod >= 20) {
    colorClass = "text-b-yellow text-xl";
    grade = "B";
  } else if (warrantyPeriod >= 15) {
    colorClass = "text-c-blue text-xl";
    grade = "C";
  } else if (warrantyPeriod >= 10) {
    colorClass = "text-d-orange text-xl";
    grade = "D";
  } else if (warrantyPeriod >= 1) {
    colorClass = "text-hot-pink text-xl";
    grade = "F";
  }

  return { colorClass, grade };
};

const formatWarrantyValue = (value) => {
  return value === null ? "Not Found" : `${value} years`;
};

const WarrantyInfo = ({ data }) => {
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const warrantiesTooltip = () => {
    return (
      <div className="hidden group-hover:block absolute left-0 mt-2 p-3 flex items-center bg-gray-800 text-white rounded-md w-[150px]">
        <div>
          <p className="text-xs">
            Your solar installation is covered by multiple warranties.
          </p>
          <p className="text-xs mt-2">
            Ideally, all parts of your system would be covered under warranty
            for 25 years.
          </p>
          <p className="text-xs mt-2">
            By hovering over each warranty, you can learn what that warranty is
            for.
          </p>
        </div>
      </div>
    );
  };

  const warrantyData = [
    {
      title: "Workmanship",
      tooltip:
        "A workmanship warranty should be offered by the contractor to cover the quality and reliability of the installation work itself.",
      value: data["Workmanship"] !== "Not Found" ? data["Workmanship"] || 0 : null,
      key: "Workmanship"
    },
    {
      title: "Roof Leak",
      tooltip: (
        <div>
          <p>Roof Leak warranties are a type of workmanship warranty that should be offered by the contractor to specifically covers roof leaks.</p><br></br>
          <p>Roof leak warranties guarantee that the solar installation won't cause leaks in the roof and that the contractor will take responsibility if there is an issue.</p>
        </div>
      ),
      value: data["RoofPenetration"] !== "Not Found" ? data["RoofPenetration"] || 0 : null,
      key: "RoofPenetration"
    },
    {
      title: "Panel Manufacturing Defects",
      tooltip: (
        <div>
          <p>This warranty is offered by the manufacturer of the solar panels. It should cover any defects in the manufacturing of the panels.</p>
        </div>
      ),
      value: data["PanelProductWarranty"] !== "Not Found" ? data["PanelProductWarranty"] || 0 : null,
      key: "PanelProductWarranty"
    },
    {
      title: "Panel Production",
      tooltip:
        "This warranty is offered by the manufacturer of the solar panels. It guarantees that the solar panels will produce a certain amount of energy over time.",
      value: data["ProductionWarranty"] !== "Not Found" ? data["ProductionWarranty"] || 0 : null,
      key: "ProductionWarranty"
    },
    {
      title: "Inverter",
      tooltip: (
        <div>
          <p>The inverter converts the electricity from the solar panels into electricity that can be used by your home.</p><br></br>
          <p>This warranty is offered by the manufacturer of the inverter to cover any manufacturing defects in the inverter/s.</p>
        </div>
      ),
      value: data["InverterWarranty"] !== "Not Found" ? data["InverterWarranty"] || 0 : null,
      key: "InverterWarranty"
    },
  ];

  return (
    <div className="flex w-[800px] max-w-full flex-col px-7 mt-12 self-start max-md:mt-10">
      <div className="self-stretch max-md:max-w-full max-md:pr-5">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch max-md:w-[30%] md:min-w-[124px] max-md:w-full max-md:ml-0">
            <div className="relative group cursor-pointer">
              <div className="text-neutral-700 text-xl font-semibold leading-8 whitespace-nowrap max-md:mt-10">
                Warranties
              </div>
              {warrantiesTooltip()}
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[70%] ml-5 max-md:w-full max-md:ml-0">
            <div className="flex flex-col justify-start items-stretch max-md:mt-10">
              {warrantyData.map((item, index) => {
                const isWarrantyLoading = !data.hasOwnProperty(item.key);
                const warrantyValue = data[item.key];

                const displayValue = isWarrantyLoading
                  ? <Skeleton />
                  : warrantyValue === "Not Found"
                    ? "Not Found"
                    : formatWarrantyValue(warrantyValue);

                return (
                  <div
                    key={index}
                    className={`relative group ${item.tooltip ? "cursor-pointer" : ""}`}
                    onMouseEnter={() => setTooltipIndex(index)}
                    onMouseLeave={() => setTooltipIndex(null)}
                    style={{
                      marginBottom: index !== warrantyData.length - 1 ? "40px" : "0",
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <div className="text-neutral-700 text-base font-semibold leading-[32px]">
                          {item.title}
                        </div>
                        <div className="flex">
                          <div className={`font-bold leading-8 ${getColoredLetter(warrantyValue).colorClass}`}>
                            {isWarrantyLoading ? "" : getColoredLetter(warrantyValue).grade}
                          </div>
                          <div className={`text-neutral-700 text-base leading-8 ${!isWarrantyLoading && displayValue !== "Not Found" ? "ml-3" : ""}`}>
                            {displayValue}
                          </div>
                        </div>
                      </div>
                      <Tooltip
                        text={item.tooltip}
                        hasValue={!isWarrantyLoading && warrantyValue !== "Not Found"}
                        showTooltip={tooltipIndex === index}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantyInfo;

