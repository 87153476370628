import React from 'react';
import Footer from './Footer';
import { useState } from 'react';

const Changelog = () => {
  const [changelogEntries, setChangelogEntries] = useState([
    {
      date: '2023-04-01',
      old: 'Feature not available',
      new: 'New feature added'
    },
    {
      date: '2023-03-15',
      old: 'Old design',
      new: 'New and improved design'
    },
    {
      date: '2023-02-28',
      old: 'Slow performance',
      new: 'Optimized performance'
    }
  ]);

  const [newDate, setNewDate] = useState('');
  const [newOld, setNewOld] = useState('');
  const [newNew, setNewNew] = useState('');

  const addChangelogEntry = () => {
    if (newDate && newOld && newNew) {
      setChangelogEntries([
        ...changelogEntries,
        {
          date: newDate,
          old: newOld,
          new: newNew
        }
      ]);
      setNewDate('');
      setNewOld('');
      setNewNew('');
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">Changelog</h1>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-4 text-left">Date</th>
            <th className="p-4 text-left">Old</th>
            <th className="p-4 text-left">New</th>
          </tr>
        </thead>
        <tbody>
          {changelogEntries.map((entry, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
              <td className="p-4">{entry.date}</td>
              <td className="p-4">{entry.old}</td>
              <td className="p-4">{entry.new}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-6">
        <h2 className="text-xl font-bold mb-4">Add New Entry</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Date (YYYY-MM-DD)"
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
            className="p-2 border border-gray-300 rounded-md flex-1"
          />
          <input
            type="text"
            placeholder="Old"
            value={newOld}
            onChange={(e) => setNewOld(e.target.value)}
            className="p-2 border border-gray-300 rounded-md flex-1"
          />
          <input
            type="text"
            placeholder="New"
            value={newNew}
            onChange={(e) => setNewNew(e.target.value)}
            className="p-2 border border-gray-300 rounded-md flex-1"
          />
          <button
            onClick={addChangelogEntry}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
          >
            Add Entry
          </button>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default Changelog;
