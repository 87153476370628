import React from 'react';
import Footer from './Footer';

const TermsOfServicePage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-4">Terms of Service for Solar Proposal Review App</h1>
      <p className="text-sm mb-2">Last Updated: February 9th, 2024</p>
      <p>Welcome to the Solar Proposal Review App ("Service"), a platform designed to provide users with automatic reviews of PDF solar proposals. By accessing or using our Service, you agree to be bound by the terms and conditions contained in this Terms of Service ("Terms"). If you do not agree with these Terms, please do not use our Service.</p>

      <h2 className="mt-4 mb-2 font-semibold">1. Use of Our Service</h2>
      <p>The Solar Proposal Review App allows users to upload PDF solar proposals to receive an automatic review based on predefined criteria. This Service is intended to assist users in evaluating solar proposals but should not be used as the sole basis for making decisions regarding solar investments or installations.</p>

      <h2 className="mt-4 mb-2 font-semibold">2. No Professional Advice</h2>
      <p>The reviews provided by our Service are based on automated processes and do not constitute professional advice. We are not responsible for the accuracy or reliability of the review outcomes. Users should consult with professional advisors for specific advice tailored to their situation.</p>

      <h2 className="mt-4 mb-2 font-semibold">3. No Liability</h2>
      <p>We assume no liability for any decisions made or actions taken in reliance on the review provided by our Service. Our Service is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

      <h2 className="mt-4 mb-2 font-semibold">4. Limitation of Liability</h2>
      <p>To the fullest extent permitted by applicable law, in no event will the Solar Proposal Review App, its affiliates, officers, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Service; (b) any conduct or content of any third party on the Service; or (c) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose.</p>

      <h2 className="mt-4 mb-2 font-semibold">5. User Responsibility</h2>
      <p>Users are solely responsible for the decisions they make based on the use of our Service. It is the user's responsibility to review and critically assess the review provided before making any decisions based on the review results.</p>

      <h2 className="mt-4 mb-2 font-semibold">6. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will provide notice of any changes by posting the updated Terms on our Service and updating the "Last Updated" date. Your continued use of the Service after such changes have been notified will constitute your consent to those changes.</p>

      <h2 className="mt-4 mb-2 font-semibold">7. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Service provider is established, without regard to its conflict of law provisions.</p>

      <h2 className="mt-4 mb-2 font-semibold">8. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at dane@danemyers.com.</p>

      <h2 className="mt-4 mb-2 font-semibold">9. Privacy of User Data</h2>

      <p>We prioritize the privacy and security of our users' data. SaferSolar.ai does not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our Service, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>



      <p className="mt-4">By using the Solar Proposal Review App, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>

      <div className="container mx-auto mt-20 max-w-5xl">
        <Footer />
      </div>

    </div>
  );
};

export default TermsOfServicePage;
