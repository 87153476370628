import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

/**
 * AddressModal component.
 * 
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The AddressModal component.
 */
const AddressModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [address, setAddress] = useState('');
  const [submitted, setSubmitted] = useState(false); // New state to track submission

  const handleSubmit = async () => {
    setSubmitted(true); // Indicate that submission has happened
    onSubmit(address);
  };

  // If isLoading is true, we might want to disable the button or show a spinner
  // Now we also check the submitted state
  const isSubmitDisabled = isLoading || !address || submitted;

  useEffect(() => {
    let timer;
    if (submitted) {
      // Close the modal after 2 seconds
      timer = setTimeout(() => {
        onClose();
        setSubmitted(false); // Reset the submitted state for the next open
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [submitted, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Address Needed</h2>
          <button onClick={onClose} className="text-black hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        {!submitted ? (
          // Show input and submit button if not submitted
          <>
            <p className="mb-4">Please provide your address for analysis.</p>
            <input
              type="text"
              placeholder="123 Palm Tree Road, Orlando, FL 32801"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="input border border-gray-300 p-2 mb-4 w-full rounded"
              disabled={isLoading}
            />
            <button
              onClick={handleSubmit}
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isSubmitDisabled}
            >
              Submit
            </button>
          </>
        ) : (
          // Show thank you message if submitted
          <p className="text-center">Thank you! We are processing the new data.</p>
        )}
      </div>
    </div>,
    document.body
  );
};

export default AddressModal;