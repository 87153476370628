/** @format */
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

//Components
import PrivacyPolicy from "../src/components/privacypolicy";
import TermsOfServicePage from "../src/components/termsofservice";
import AboutUs from "./components/about";
import Changelog from "./components/changelog";
import "./tailwind.css";

//UTILS

// Import the frontend repo
import { Page } from "safersolar";

import SolarRoof from "./components/SolarRoof";
import SignIn from "./components/SignIn";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "/packages/firemade/src/index.js";
import Results from "../src/Page/Results";
/**
 * Retrieves the project name from the current window location.
 * The reason this component has been structured this way is to allow
 * for multipl projects on the same structure.
 * @returns {string} The project name.
 */

/**
 * The main component of the application.
 *
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
	const [user] = useAuthState(auth)
	// This should be replaced with a loading gif
	return (
		<div className="app">
			<Router>
				<Routes>
					<Route exact path="/" element={user ? <Page /> : <SignIn />} />
					<Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
					<Route path="/TermsOfService" element={<TermsOfServicePage />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/changelog" element={<Changelog />} />
					<Route path="/results/:documentId" element={<Page />} />
					<Route path="/results" element={Results} />
					<Route
						path="/solarroof"
						element={<SolarRoof address="710 Main Street Venice California" />}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;