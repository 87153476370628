/** @format */

export const binaryPalette = ["FFFFFF", "FFFF00", "FF0000", "FFFF00"];

export const rainbowPalette = [
	"3949AB",
	"81D4FA",
	"66BB6A",
	"FFE082",
	"E53935",
];

export const ironPalette = ["00000A", "91009C", "E64616", "FEB400", "FFFFF6"];

export const sunlightPalette = ["212121", "FFCA28"];

export const panelsPalette = ["E8EAF6", "1A237E"];
